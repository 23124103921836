import { Attrs, RVOLConfig } from '../indicators/configs/rvol'
import { MainCalculation } from './main'

type DefaultCalculatedValuesType = {
  rvol: number[]
}

export class RVOLCalculation extends MainCalculation<Attrs, DefaultCalculatedValuesType> {
  static config = RVOLConfig

  calculate() {
    const { period } = this.options
    const { volume } = this.quote
    this._calculatedValues = this.getDefaultCalculatedValues()

    if (volume.length < period) return

    let sum = 0
    for (let i = 0; i < volume.length; i++) {
      sum += volume[i] - (volume[i - period] ?? 0)
      if (i < period - 1) continue
      this._calculatedValues.rvol[i] = sum > 0 ? volume[i] / (sum / period) : 0
    }
  }
}
