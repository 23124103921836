import { Attrs, WRConfig } from '../indicators/configs/wr'
import { MainCalculation } from './main'

type DefaultCalculatedValuesType = {
  r: number[]
}

export class WRCalculation extends MainCalculation<Attrs, DefaultCalculatedValuesType> {
  static config = WRConfig

  calculate() {
    const { period } = this.options
    const { high, low, close } = this.quote
    this._calculatedValues = this.getDefaultCalculatedValues()

    for (let i = period - 1; i < close.length; i++) {
      let highestHigh = high[i]
      let lowestLow = low[i]
      for (let j = i - period + 1; j <= i; j++) {
        if (high[j] > highestHigh) {
          highestHigh = high[j]
        }
        if (low[j] < lowestLow) {
          lowestLow = low[j]
        }
      }

      if (i < period - 1) continue

      this._calculatedValues.r[i] = 0
      if (highestHigh - lowestLow > 0) {
        this._calculatedValues.r[i] = ((highestHigh - close[i]) / (highestHigh - lowestLow)) * -100
      }
    }
  }
}
