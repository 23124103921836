import * as dateFns from 'date-fns'

import { Instrument } from '../../types/shared'
import Text from '../canvas/text'
import { TIMEFRAME, TextBaseline } from '../constants/common'
import {
  PPCalculation,
  PivotPeriod,
  getIsSameDateInPeriod,
  getPeriodKeyFormat,
  getPeriodType,
} from '../indicator-calculation/pp'
import Chart from '../models/chart'
import Utils from '../utils'
import { getBarWidthWithMargin, round } from '../utils/chart'
import { getAreNoBarsVisible, getVisibleBarToRenderIndex } from '../utils/draw_in_visible_area'
import { Attrs, PPCalculationTypeEnum, PPCalculationTypeLabels, PPConfig } from './configs/pp'
import Overlay from './overlay'

const DEFAULT_PARAMETERS = {
  CalculationType: PPCalculationTypeEnum.standard,
  Color: '#FFA75F',
}
const MINUTES_PER_DAY = 1440

function getCalculationType(period: string) {
  // standard & fibonacci were legacy values, kept in parsing for backward compatability purposes
  switch (period) {
    case 'fibonacci':
    case '1':
      return PPCalculationTypeEnum.fibonacci
    case 'standard':
    case '0':
    default:
      return PPCalculationTypeEnum.standard
  }
}

class PivotPoints extends Overlay<Attrs> {
  static config = PPConfig

  declare ppCalculation: PPCalculation

  set(obj: Partial<Attrs>) {
    super.set(obj)
    const { period } = obj
    if (period) {
      this.attrs.calculationType = getCalculationType(period)
      this.trigger('change')
    }
    return this
  }

  fx = (x: number) => {
    const lastIndex = this.data.close.length - 1
    const outsideBar = this.data.barIndex[lastIndex] + x - lastIndex

    return this.model.scale.x(this.data.barIndex[x] ?? outsideBar)
  }

  renderContent(context: CanvasRenderingContext2D) {
    super.renderContent()
    if (this.data.close.length === 0) return

    const chartModel = this.model.chart() as Chart
    const { leftOffset, width, zoomFactor } = chartModel
    const { left, right } = chartModel.getChartLayoutSettings().ChartSettings
    const chartWidth = width - left.width - right.width

    if (this.isComputeNecessary()) {
      this.ppCalculation = new PPCalculation({
        quote: this.data,
        options: {
          calculationType: this.attrs.calculationType,
        },
      })
      this.ppCalculation.calculate()
    }

    const barWidth = getBarWidthWithMargin({
      zoomFactor,
      chartLayout: chartModel.chart_layout(),
    })

    const firstBarToRender = getVisibleBarToRenderIndex({
      quote: this.data,
      paneModel: this.model,
      leftOffset,
    })
    const lastBarToRender = getVisibleBarToRenderIndex({
      quote: this.data,
      paneModel: this.model,
      leftOffset,
      chartWidth,
    })

    const areNoBarsVisible = getAreNoBarsVisible(firstBarToRender, lastBarToRender)
    if (areNoBarsVisible) return

    const text = (label: string, price: number, calculatedX: number) => {
      new Text(
        {
          x: calculatedX,
          y: this.fy(price) - 13,
          font: { size: 8, weight: '900' },
          textBaseline: TextBaseline.top,
          fillStyle: this.attrs.color,
          text: `${label} (${round({ data: this.data, num: price })})`,
        },
        this.model
      ).render(context)
    }

    const line = (fromX: number, toX: number, y: number) => {
      context.moveTo(fromX, this.fy(y))
      context.lineTo(toX, this.fy(y))
    }

    const isStock = this.data.instrument === Instrument.Stock
    const periodType = getPeriodType(this.data.timeframe)
    const periodKeyFormat = getPeriodKeyFormat(periodType)
    let currentDate = new Date()
    let lastDate = new Date()

    context.translate(0.5, 0.5)
    context.set('strokeStyle', this.attrs.color)

    for (let index = firstBarToRender.dataIndex; index <= lastBarToRender.dataIndex; index++) {
      currentDate = Utils.dateFromUnixTimestamp(this.data.date[index])

      // check if new calculation/render is necessary
      if (getIsSameDateInPeriod(lastDate, currentDate, periodType)) continue
      lastDate = currentDate

      // prepare x coordinates for lines rendering
      let fromX = this.fx(index)
      let toX = this.fx(index)
      switch (periodType) {
        case PivotPeriod.day:
          const interval = this.data.interval
          const currentX = fromX

          const toDate = new Date(currentDate)
          toDate.setHours(16, 0, 0, 0)
          if (this.data.aftermarket && interval <= 5) toDate.setHours(18, 30, 0, 0)
          if (interval <= 15 && !isStock) toDate.setHours(23, 59, 59, 99)
          const toTimestamp = toDate.getTime() / 1000
          const toDiffMinutes = (toTimestamp - currentDate.getTime() / 1000) / 60
          const toBars = toDiffMinutes / interval
          toX = Math.min(currentX + toBars * barWidth, -leftOffset + width)

          const fromDate = new Date(currentDate)
          fromDate.setHours(9, 30, 0, 0)
          if (this.data.premarket && interval <= 5) fromDate.setHours(7, 0, 0, 0)
          if (interval <= 15 && !isStock) fromDate.setHours(0, 0, 0, 0)
          const fromTimestamp = fromDate.getTime() / 1000
          const fromDiffMinutes = (fromTimestamp - currentDate.getTime() / 1000) / 60
          const fromBars = fromDiffMinutes / interval
          fromX = currentX + fromBars * barWidth
          break

        case PivotPeriod.week:
          for (let i = index + 1; i <= lastBarToRender.dataIndex; i++) {
            const nextDate = Utils.dateFromUnixTimestamp(this.data.date[i])
            if (!dateFns.isSameWeek(currentDate, nextDate, { weekStartsOn: 1 })) {
              toX = this.fx(i)
              break
            }
          }
          for (let i = index - 1; i >= firstBarToRender.dataIndex; i--) {
            const nextDate = Utils.dateFromUnixTimestamp(this.data.date[i])
            if (!dateFns.isSameWeek(currentDate, nextDate, { weekStartsOn: 1 })) {
              fromX = this.fx(i + 1) // because we need start from current week
              break
            }
          }

          if (fromX === toX) {
            // we need line to imaginary end of the week
            const barsPerDay = Math.ceil((this.data.drawMinutesPerDay ?? MINUTES_PER_DAY) / this.data.interval)
            toX += (isStock ? 5 : 7) * barsPerDay * barWidth
          }
          break

        case PivotPeriod.month:
          for (let i = index + 1; i <= lastBarToRender.dataIndex; i++) {
            const nextDate = Utils.dateFromUnixTimestamp(this.data.date[i])
            if (nextDate.getMonth() !== currentDate.getMonth()) {
              toX = this.fx(i)
              break
            }
          }
          for (let i = index - 1; i >= firstBarToRender.dataIndex; i--) {
            const nextDate = Utils.dateFromUnixTimestamp(this.data.date[i])
            if (nextDate.getMonth() !== currentDate.getMonth()) {
              fromX = this.fx(i + 1) // because we need start from current month
              break
            }
          }

          if (fromX === toX) {
            // we need line to imaginary end of the month
            const barsPerDay = this.data.isIntraday
              ? Math.ceil((this.data.drawMinutesPerDay ?? MINUTES_PER_DAY) / this.data.interval)
              : 1
            toX += (isStock ? 20 : 30) * barsPerDay * barWidth
          }
          break
        default:
          for (let i = index + 1; i <= lastBarToRender.dataIndex; i++) {
            const nextDate = Utils.dateFromUnixTimestamp(this.data.date[i])
            if (nextDate.getFullYear() !== currentDate.getFullYear()) {
              toX = this.fx(i)
              break
            }
          }
          for (let i = index - 1; i >= firstBarToRender.dataIndex; i--) {
            const nextDate = Utils.dateFromUnixTimestamp(this.data.date[i])
            if (nextDate.getFullYear() !== currentDate.getFullYear()) {
              fromX = this.fx(i + 1) // because we need start from current year
              break
            }
          }

          if (fromX === toX) {
            // we need line to imaginary end of the year
            toX += (this.data.timeframe === TIMEFRAME.w ? 49 : 12) * barWidth
          }
          break
      }
      fromX = Math.max(fromX, -leftOffset)

      // get pivotPoint for current date from calculation
      const currentPeriodKey = dateFns.format(currentDate, periodKeyFormat)
      const pivotPoint = this.ppCalculation.calculatedValuesForRender.pivotPointsByPeriodKey[currentPeriodKey]
      if (!pivotPoint) continue

      // render pivot line
      context.beginPath()
      line(fromX, toX, pivotPoint.pivot)
      context.stroke()

      // render resistance and support lines
      context.save()
      context.setLineDash([3, 3])
      context.beginPath()
      line(fromX, toX, pivotPoint.res1)
      line(fromX, toX, pivotPoint.res2)
      line(fromX, toX, pivotPoint.res3)
      line(fromX, toX, pivotPoint.sup1)
      line(fromX, toX, pivotPoint.sup2)
      line(fromX, toX, pivotPoint.sup3)
      context.stroke()
      context.restore()

      // render labels if there is enough space
      if (toX - fromX >= 48) {
        text('P', pivotPoint.pivot, fromX)
        text('R1', pivotPoint.res1, fromX)
        text('R2', pivotPoint.res2, fromX)
        text('R3', pivotPoint.res3, fromX)
        text('S1', pivotPoint.sup1, fromX)
        text('S2', pivotPoint.sup2, fromX)
        text('S3', pivotPoint.sup3, fromX)
      }
    }

    context.translate(-0.5, -0.5)
  }

  getModalConfig() {
    const options = {
      calculationType: {
        type: 'select',
        label: 'Calculation Type',
        name: 'calculationType',
        value: this.attrs.calculationType ?? DEFAULT_PARAMETERS.CalculationType,
        required: true,
        items: [
          {
            value: PPCalculationTypeEnum.standard,
            label: PPCalculationTypeLabels[PPCalculationTypeEnum.standard],
          },
          {
            value: PPCalculationTypeEnum.fibonacci,
            label: PPCalculationTypeLabels[PPCalculationTypeEnum.fibonacci],
          },
        ],
      },
      color: {
        type: 'color',
        label: 'Color',
        name: 'color',
        value: this.attrs.color ?? this.getFreeColor(),
      },
    }

    return {
      title: PPConfig.label,
      inputs: PPConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {},
    }
  }

  getIsValid(key: string) {
    switch (key) {
      case 'calculationType':
        return Object.values(PPCalculationTypeEnum).includes(this.attrs[key])
      case 'color':
        return !!this.attrs[key]
      default:
        return false
    }
  }

  toString() {
    return `${PPConfig.shortLabel} (${PPCalculationTypeLabels[this.attrs.calculationType]})`
  }
}

PivotPoints.prototype.defaults = {
  calculationType: DEFAULT_PARAMETERS.CalculationType,
  color: DEFAULT_PARAMETERS.Color,
}

export default PivotPoints
