import { Attrs, ROCConfig } from '../indicators/configs/roc'
import { MainCalculation } from './main'

type DefaultCalculatedValuesType = {
  roc: number[]
}

export class ROCCalculation extends MainCalculation<Attrs, DefaultCalculatedValuesType> {
  static config = ROCConfig

  calculate() {
    const { period } = this.options
    const { close } = this.quote
    this._calculatedValues = this.getDefaultCalculatedValues()

    for (let i = period; i < close.length; i++) {
      this._calculatedValues.roc[i] = ((close[i] - close[i - period]) / close[i - period]) * 100
    }
  }
}
