/*
 * We want to be able to render only bars/parts of line that are visible to users,
 * but without introducing visual artifacts like bars missing in front of users while scrolling
 * or lines that start in middle of chart for sparse intraday stock data.
 *
 * In general we want to start rendering lines one bar sooner than what is visible to user and stop rendering
 * one bar outside of visible area to create a continuous line.
 */
import type Pane from '../models/pane'
import type Quote from '../models/quote'
import { getHalfBarWidth } from './chart'

export const drawInVisibleArea = ({
  drawBarCallback,
  fromIndexOffset = 0,
  fxOverride,
  leftOffset,
  paneModel,
  quote,
  toIndexOffset = 0,
  width,
}: {
  drawBarCallback: (i: number, x: number) => void
  fromIndexOffset?: number
  fxOverride?: (index: number) => number
  leftOffset: number
  paneModel: Pane
  quote: Quote
  toIndexOffset?: number
  width: number
}) => {
  const fx = fxOverride ?? ((index: number) => paneModel.scale.x(quote.barIndex[index]))

  // We want to start rendering one bar outside of the visible area for two reasons:
  // 1. To create a seamless transition for bars entering/leaving the view, avoiding them from
  //    abruptly dis/appearing when half the bar is outside.
  // 2. To prevent disconnected lines in case there's a bar gap.
  // However, if a `fromIndex` is provided and it's higher than the first visible bar,
  // we need to start rendering from that specific index. This ensures correct starting
  // points for elements like indicators that rely on this value.
  const firstBarToRenderIndex = getCompensatedFirstBarToRenderIndex({ quote, paneModel, leftOffset, fromIndexOffset })

  let lastBarToRender = -1
  for (let i = firstBarToRenderIndex; i < quote.close.length + toIndexOffset; i++) {
    const x = fx(i)
    if (x + leftOffset > width) {
      lastBarToRender = i
      break
    }
    drawBarCallback(i, x)
  }

  if (lastBarToRender > -1) {
    const x = fx(lastBarToRender)
    drawBarCallback(lastBarToRender, x)
  }
}

/**
 * Get first or last visible bar index.
 * IMPORTANT: If chartWidth is provided, return is lastVisibleBarIndex
 */
export function getVisibleBarToRenderIndex({
  chartWidth,
  leftOffset,
  paneModel,
  quote,
}: {
  chartWidth?: number
  leftOffset: number
  paneModel: Pane
  quote: Quote
}) {
  const chartModel = paneModel.chart()
  // barIndex & dataIndex match for non-intraday timeframes,
  // however intraday timeframes have pre-derminated count of bars per certain timeframe eg. trading day
  // so count of data doesn't have to match with count of bars that are displayed,
  // if bar doesn't have data, we call that a bar gap

  // barIndex is index of bar at the edge of visible chart area, either first or last
  const barIndex = Math.round(paneModel.scale.x.invert(-leftOffset + (chartWidth !== undefined ? chartWidth : 0)))
  // dataIndex is index of actual real data
  // not all the time there is data present on barIndex calculated via inverted leftOffset,
  // there might be a gap between bars, if there is, barToDataIndex on that gap spot will have value of
  // previous bar which isn't visible
  const dataIndex = quote.barToDataIndex[Math.min(Math.max(barIndex, 0), quote.barToDataIndex.length - 1)]
  const halfBarWidth = getHalfBarWidth(chartModel, false)

  // if barIndex doesn't match barIndex value at the dataIndex we know that that bar index doesn't have data
  const haveBarIndexData = barIndex === quote.barIndex[dataIndex]
  const isDataIndexVisible = Math.round(paneModel.scale.x(quote.barIndex[dataIndex]) + halfBarWidth) + leftOffset > 0

  const barToRenderObject = {
    barIndex,
    dataIndex,
    isDataIndexVisible,
    haveBarIndexData,
  }

  // If chartWidth isn't provided we are getting firstVisibleBarToRender
  if (chartWidth === undefined) {
    // If bar isn't visible we do dataIndex + 1 because we want to get next real bar as we know current isn't visible,
    // this doesn't mean that next is as we might be on view where there are none bars visible,
    // if that is the case, then firstVisibleBarToRender.index > lastVisibleBarToRender.index || none bars are visible
    barToRenderObject.dataIndex = isDataIndexVisible
      ? dataIndex
      : Math.max(Math.min(dataIndex + 1, quote.barIndex.length - 1), 0)
    return barToRenderObject
  }

  // If chartWidth is provided we are getting lastVisibleBarToRender
  return barToRenderObject

  /**
   * Setup:
   * firstBarToRender (fb), lastBarToRender (lb), both variables contain index and dataIndex in format (index, dataIndex)
   * on intraday charts where we can have bar gaps so each bar have index value and dataIndex value,
   * as it is best for edge cases we would use that for our cases
   * we would refer to that like eg. (0,0) - (index, dataIndex), if index value doesn't match dataIndex
   * it means that there is bar gap, in that case dataIndex would have value of most recent existing bar with data
   * eg. (0,0),(1,1),(2,1),(3,2)...
   * for ilustrating what is visible chart area we would use square brackets []
   * eg. (0,0),(1,1),(2,2) [ (3,3),(4,4),(5,5),(6,6),(7,7) ] (8,8),(9,9),(10,10)
   * visible bars in example are bars 3 to 7
   *
   * Cases:
   * 1: first and last bars are in place - (0,0),(1,1),(2,2) [ (3,3),(4,4),(5,5),(6,6),(7,7) ] (8,8),(9,9),(10,10)
   * 2: first bar visible last bar not - (0,0),(1,1),(2,2) [ (3,3),(4,3),(5,3),(6,3),(7,3) ] (8,4),(9,5),(10,6)
   * 3: first bar not visible last bar visible - (0,0),(1,1),(2,2) [ (3,2),(4,2),(5,2),(6,2),(7,3) ] (8,4),(9,5),(10,6)
   * 4: first and last bar not visible (no bars visible) - (0,0),(1,1),(2,2) [ (3,2),(4,2),(5,2),(6,2),(7,2) ] (8,3),(9,4),(10,5)
   * 5: first and last bar not visible (bars in the middle) - (0,0),(1,1),(2,2) [ (3,2),(4,3),(5,4),(6,5),(7,5) ] (8,6),(9,7),(10,8)
   * 6: first bar not visible but no previous bar existing, last bar visible - [ (0,-),(1,-),(2,-),(3,0),(4,1) ] (5,2),(6,3),(7,4), (8,5),(9,6),(10,7) // in reality it would be [ (0,0),(1,0),(2,0),(3,0),(4,1) ] (5,2),(6,3),(7,4), (8,5),(9,6),(10,7)
   * 7: first bar visible last bar not visible but no later bars exists - (0,0),(1,1),(2,2),(3,3),(4,4),(5,5),(6,6) [ (7,7),(8,8),(9,-),(10,-) ] // in reality it would be (0,0),(1,1),(2,2),(3,3),(4,4),(5,5),(6,6) [ (7,7),(8,8),(9,8),(10,8) ]
   *
   * Current behaviour:
   *
   * Case: 1 - fb(3,3), lb(7,7)
   * Case: 2 - fb(3,3), lb(7,3)
   * Case: 3 - fb(3,3), lb(7,3)
   * Case: 4 - fb(3,3), lb(7,2)
   * Case: 5 - fb(3,3), lb(7,5)
   * Case: 6 - fb(0,0), lb(4,1)
   * Case: 7 - fb(7,7), lb(10,8)
   */
}

// Get first bar to render compensated for fromIndexOffset
export function getCompensatedFirstBarToRenderIndex({
  fromIndexOffset = 0,
  leftOffset,
  paneModel,
  quote,
}: {
  fromIndexOffset?: number
  leftOffset: number
  paneModel: Pane
  quote: Quote
}) {
  return Math.max(0, getVisibleBarToRenderIndex({ quote, leftOffset, paneModel }).dataIndex - 1, fromIndexOffset)
}

type IBarToRender = ReturnType<typeof getVisibleBarToRenderIndex>
/**
 * Checks if there are no visible bars between the specified first and last bars to render.
 * IMPORTANT: Provided indexes should be without bar gap compensation.
 *
 * @param {IBarToRender} firstBarToRenderIndex The index of the first bar to render.
 * @param {IBarToRender} lastBarToRenderIndex The index of the last bar to render.
 * @returns {boolean} True if there are no visible bars between the first and last bars, false otherwise.
 */
export function getAreNoBarsVisible(firstBarToRenderIndex: IBarToRender, lastBarToRenderIndex: IBarToRender): boolean {
  return !firstBarToRenderIndex.isDataIndexVisible && !lastBarToRenderIndex.isDataIndexVisible
}
