import { Instrument } from '../../types/shared'
import { ChartElementType } from '../constants/common'
import PaneModel from '../models/pane'
import QuoteModel from '../models/quote'

export function getInstrumentLabel(instrument: Instrument) {
  switch (instrument) {
    case Instrument.Stock:
      return 'Stock'
    case Instrument.Futures:
      return 'Futures'
    case Instrument.Forex:
      return 'Forex'
    case Instrument.Crypto:
      return 'Crypto'
    case Instrument.MarketSentiment:
      return 'Market Sentiment'
  }
}

export function getChartAlt(paneModel: PaneModel, quoteModel: QuoteModel) {
  const element = paneModel.getChartOrIndicatorElement()
  let altText = `${quoteModel.name || quoteModel.ticker} - `
  if (element?.isIndicator()) {
    altText += `${element.instance.seoLabel} Indicator`
  } else {
    altText += `${getInstrumentLabel(quoteModel.instrument)} Price Chart`
  }
  return altText
}

export const getResponseChartType = (chartType: ChartElementType) => {
  switch (chartType) {
    case ChartElementType.HeikinAshi:
      return 'heikinashi'
    case ChartElementType.HollowCandleStick:
      return 'hollowcandlestick'
    case ChartElementType.LineChart:
      return 'line'
    case ChartElementType.OhlcChart:
      return 'ohlc'
    case ChartElementType.CandleStick:
    default:
      return 'candlestick'
  }
}

export const getChartElementTypeFromResponseType = (chartResponseType: string | null) =>
  chartResponseType === null
    ? null
    : Object.values(ChartElementType).find(
        (chartType: ChartElementType) => chartResponseType === getResponseChartType(chartType)
      )
