import { ObjectHash } from '../../types/shared'
import Text from '../canvas/text'
import { ChartElementType, FONT_SIZE, MARGIN, OFFSET, PADDING, TextAlign, TextBaseline } from '../constants/common'
import { getTranslate } from '../controllers/renderUtils'
import Pane from '../models/pane'
import Quote from '../models/quote'
import utils from '../utils'
import Chart, { BaseChartAttrs } from './base_chart'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const NO_VALUE = ' - '

interface PerfChartAttrs extends BaseChartAttrs {
  tickers: string[]
  label?: string
  fromDate: string
  toDate: string
  min?: number
  max?: number
}

class PerfChart extends Chart<PerfChartAttrs> {
  static type = ChartElementType.PerfChart

  overlayLabelWidth: ObjectHash<number>
  perf: ObjectHash<Array<number | null>> = {}
  dates: number[] = []

  constructor(values: Partial<PerfChartAttrs>, model: Pane) {
    super(values, model)
    this.renderText = this.renderText.bind(this)
    this.renderOverlaysLabels = this.renderOverlaysLabels.bind(this)
    this.renderYAxis = this.renderYAxis.bind(this)
    this.overlayLabelWidth = {}
  }

  fx = (i: number) => this.paneModel.scale.x(i)

  getCompleteChartNumOfBars() {
    return this.dates.length
  }

  renderChart() {
    const { ChartSettings, ColorsSettings } = this.getChartLayoutSettings()
    const translate = getTranslate({
      context: this.context,
      xOffset: this.leftOffset + ChartSettings.left.width + 0.5,
      yOffset: ChartSettings.top.height + 0.5,
    })
    translate.do()

    this.context.beginPath()
    this.context.set('lineWidth', 1)
    for (let index = 0; index < this.attrs.tickers.length; index++) {
      const ticker = this.attrs.tickers[index]
      if ((this.perf[ticker] != null ? this.perf[ticker].length : 0) > 0) {
        this.context.set('strokeStyle', ColorsSettings[index % ColorsSettings.length])
        this.context.beginPath()
        // We don't need to calculate first/last visible bars because the data is already cropped
        for (let i = 0; i < this.perf[ticker].length; i++) {
          const value = this.perf[ticker][i]
          if (value !== null && Number.isFinite(value)) {
            this.context.lineTo(this.fx(i), Math.round(this.fy(value)))
          }
        }
        this.context.stroke()
      }
    }

    translate.undo()
  }

  renderText(context: CanvasRenderingContext2D) {
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const period = (() => {
      switch (this.model.quote().timeframe) {
        case 'd':
          return 'DAILY'
        case 'w':
          return 'WEEKLY'
        case 'm':
          return 'MONTHLY'
        default:
          return
      }
    })()
    const y = (this.height - 4) / 2
    new Text(
      {
        text: period,
        x: 28,
        y: ChartSettings.top.height + y,
        angle: -90,
        font: { size: 10, weight: '900', family: 'Lato, sans-serif' },
        fillStyle: Colors.textSecondary,
        textAlign: TextAlign.center,
        textBaseline: TextBaseline.alphabetic,
      },
      this.paneModel
    ).render(context)
  }

  renderCrossText(context: CanvasRenderingContext2D, crossIndex: number) {
    const { ChartSettings, ColorsSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const date = utils.dateFromUnixTimestamp(this.dates[crossIndex] ?? 0)
    let dateString = ''
    const month = date.getMonth() // 20141126
    const year = date.getFullYear()
    const day = date.getDate()
    switch (this.data.timeframe) {
      case 'd':
      case 'w':
        dateString = `${months[month]} ${day}`
        break
      case 'm':
        dateString = `${months[month]} ${year}`
        break
      default:
        return
    }

    let shouldRenderDate = false
    for (let index = 0; index < this.attrs.tickers.length; index++) {
      const ticker = this.attrs.tickers[index]
      const tickerData = this.perf[ticker]
      if (tickerData?.length) {
        context.fillStyle = Colors.canvasFill
        context.fillRect(
          ChartSettings.left.width - PADDING.XXXS,
          OFFSET.M + (FONT_SIZE.M + MARGIN.XS) * index - PADDING.XXXS,
          PADDING.XXXS + this.overlayLabelWidth[ticker] + PADDING.XXXS,
          PADDING.XXXS + FONT_SIZE.M + PADDING.XXXS
        )
        const value = tickerData[crossIndex] ?? null
        const percentageText = value !== null ? `${value.toFixed(2)}%` : NO_VALUE
        shouldRenderDate = shouldRenderDate || value !== null
        new Text(
          {
            text: `${ticker} ${percentageText}`,
            x: ChartSettings.left.width,
            y: OFFSET.M + (FONT_SIZE.M + MARGIN.XS) * index,
            font: { size: FONT_SIZE.M, weight: 'bold' },
            fillStyle: ColorsSettings[index % ColorsSettings.length],
            textAlign: TextAlign.left,
            textBaseline: TextBaseline.top,
          },
          this.paneModel
        ).render(context)
      }
    }

    if (shouldRenderDate) {
      new Text(
        {
          text: `${dateString}             `,
          x: ChartSettings.left.width + 132,
          y: 30,
          font: { size: 8 },
          fillStyle: Colors.text,
          background: Colors.canvasFill,
        },
        this.paneModel
      ).render(context)
    }
  }

  renderOverlaysLabels(context: CanvasRenderingContext2D) {
    super.renderOverlaysLabels(context)
    const { ChartSettings, ColorsSettings } = this.getChartLayoutSettings()

    for (let index = 0; index < this.attrs.tickers.length; index++) {
      const ticker = this.attrs.tickers[index]
      const tickerData = this.perf[ticker]
      if (tickerData?.length) {
        const lastCrossIndex = this.perf[ticker].findLastIndex((value) => value !== null)
        const value = tickerData[lastCrossIndex] ?? null
        const percentageText = value !== null ? `${value.toFixed(2)}%` : NO_VALUE

        const text = new Text(
          {
            text: `${ticker} ${percentageText}`,
            x: ChartSettings.left.width,
            y: OFFSET.M + (FONT_SIZE.M + MARGIN.XS) * index,
            font: { size: FONT_SIZE.M, weight: 'bold' },
            fillStyle: ColorsSettings[index % ColorsSettings.length],
            textAlign: TextAlign.left,
            textBaseline: TextBaseline.top,
          },
          this.paneModel
        )
        this.overlayLabelWidth = { ...this.overlayLabelWidth, [ticker]: text.measure(context) }
        text.render(context)
      }
    }
  }

  _compute() {
    let min = Number.MAX_VALUE
    let max = Number.MIN_VALUE
    this.perf = {}
    const quotes = this.getQuotes()

    const fromDate = new Date(this.attrs.fromDate + ' 00:00')
    const toDate = new Date(this.attrs.toDate + ' 23:59')
    this.dates = []
    for (const quote of quotes) {
      for (let i = 0; i < quote.date.length; i++) {
        const timestampe = quote.date[i]
        const date = utils.dateFromUnixTimestamp(timestampe)
        if (fromDate <= date && date <= toDate && this.dates.indexOf(timestampe) === -1) {
          this.dates.push(timestampe)
        }
      }
    }

    this.dates.sort((a, b) => a - b)

    for (const quote of quotes) {
      const ticker = quote.ticker
      this.perf[ticker] = []

      const dateToIndex = quote.getDateToIndex()
      let first = null
      let lastValue = null
      let dataIndex = -1
      for (let i = 0; i < this.dates.length; i++) {
        const timestamp = this.dates[i]
        dataIndex = dateToIndex[timestamp]

        if (typeof dataIndex === 'number') {
          first = first === null ? quote.close[dataIndex] : first
          const value = (quote.close[dataIndex] * 100) / (first ?? 0) - 100
          lastValue = Number.isFinite(value) ? value : null
        }

        this.perf[ticker][i] = lastValue

        if (lastValue !== null && min > lastValue) {
          min = lastValue
        }
        if (lastValue !== null && max < lastValue) {
          max = lastValue
        }
      }
    }

    return { min, max }
  }

  renderYAxis(context: CanvasRenderingContext2D) {
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const translate = getTranslate({
      context,
      xOffset: ChartSettings.left.width,
      yOffset: ChartSettings.top.height,
    })
    translate.do()
    const ticks = this.paneModel.scale.y.ticks(10)
    const yLine = this.getYLine()
    const text = new Text(
      {
        x: this.width + 8,
        font: { size: 8 },
        fillStyle: Colors.text,
        textBaseline: TextBaseline.middle,
      },
      this.paneModel
    )
    for (const tick of ticks) {
      const y = Math.round(this.fy(tick))
      yLine.set({ y1: y, y2: y }).render(context)
      text.set({ text: tick.toFixed(2), y }).render(context)
    }
    translate.undo()
  }

  renderXAxis() {
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const translate = getTranslate({
      context: this.context,
      xOffset: this.leftOffset + ChartSettings.left.width,
      yOffset: ChartSettings.top.height,
    })
    translate.do()
    const text = new Text(
      {
        y: this.height + 18,
        font: { size: 8 },
        fillStyle: Colors.text,
        textBaseline: TextBaseline.bottom,
        textAlign: TextAlign.center,
      },
      this.paneModel
    )
    const textWidth = text.set({ text: '2020' }).measure(this.context)

    let date = new Date(this.dates[0] * 1000)
    let lastMonth = date.getMonth() + '-' + date.getFullYear()

    let monthsInData = 0
    this.dates.forEach((d, index) => {
      if (index < 1) {
        return
      }

      const date = new Date(d * 1000)
      const month = date.getMonth() + '-' + date.getFullYear()
      if (month !== lastMonth) {
        monthsInData++
      }
      lastMonth = month
    })

    const availableTickSpots = ~~(this.width / textWidth)
    const monthsToAvail = monthsInData / availableTickSpots
    let renderMonth
    if (monthsToAvail <= 1) {
      renderMonth = () => true
    } else if (monthsToAvail <= 2) {
      renderMonth = (x: number) => x % 2 === 0
    } else if (monthsToAvail <= 3) {
      renderMonth = (x: number) => x % 3 === 0
    } else if (monthsToAvail <= 4) {
      renderMonth = (x: number) => x % 4 === 0
    } else if (monthsToAvail <= 6) {
      renderMonth = (x: number) => x % 6 === 0
    } else {
      renderMonth = (x: number) => x === 0
    }

    date = new Date(this.dates[0] * 1000)
    lastMonth = date.getMonth() + '-' + date.getFullYear()

    const xLine = this.getXLine()

    for (let i = 0; i < this.dates.length; i++) {
      date = new Date(this.dates[i] * 1000)
      const month = date.getMonth() + '-' + date.getFullYear()
      if (month !== lastMonth) {
        lastMonth = month
        const x = this.fx(i)
        if (x + this.leftOffset < -15 || x + this.leftOffset > this.width + 15) {
          // margin?
          continue
        }
        xLine.set({ x1: x, x2: x }).render(this.context)

        if (!renderMonth(date.getMonth())) {
          continue
        }

        text
          .set({
            text: date.getMonth() === 0 ? date.getFullYear().toString() : months[date.getMonth()],
            x: x,
          })
          .render(this.context)
      }
    }

    translate.undo()
  }

  getMinMax() {
    return this._compute()
  }

  renderVolumeAxis() {}

  renderVolume() {}

  getQuotes() {
    return Quote.select(
      (q: Quote) =>
        this.attrs.tickers.includes(q.ticker) &&
        q.timeframe === this.data?.timeframe &&
        this.model.chart_layout().isIdeaId(q.ideaID)
    )
  }

  toString() {
    return `Perf chart ${this.data.ticker}`
  }
}

export default PerfChart
