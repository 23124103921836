import { Attrs, RWIConfig } from '../indicators/configs/rwi'
import { getTrueRange } from './atr'
import { MainCalculation } from './main'

type DefaultCalculatedValuesType = {
  rwiHigh: number[]
  rwiLow: number[]
}

export class RWICalculation extends MainCalculation<Attrs, DefaultCalculatedValuesType> {
  static config = RWIConfig

  calculate() {
    const { period } = this.options
    const { high, low, close } = this.quote
    this._calculatedValues = this.getDefaultCalculatedValues()

    if (close.length <= period + 2) return

    const tr: number[] = []

    for (let i = 0; i < close.length; i++) {
      tr[i] = getTrueRange(high[i], low[i], close[i - 1])
    }

    for (let i = period + 2; i < close.length; i++) {
      const h = high[i]
      const l = low[i]
      let trSum = tr[i - 1] + tr[i - 2]
      let count = 2
      let atrWeight = (trSum / count) * Math.sqrt(count)
      let maxHi = atrWeight > 0 ? (h - low[i - 1]) / atrWeight : -1
      let maxLo = atrWeight > 0 ? (high[i - 1] - l) / atrWeight : -1
      for (let j = i - 1; j > i - period + 1; j--) {
        trSum += tr[j - 2]
        count++
        atrWeight = (trSum / count) * Math.sqrt(count)
        if (atrWeight === 0) {
          continue
        }

        const hi = (h - low[j - 1]) / atrWeight
        if (hi > maxHi) {
          maxHi = hi
        }

        const lo = (high[j - 1] - l) / atrWeight
        if (lo > maxLo) {
          maxLo = lo
        }
      }
      this._calculatedValues.rwiHigh[i] = maxHi
      this._calculatedValues.rwiLow[i] = maxLo
    }
  }
}
