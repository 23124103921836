import merge from 'lodash.merge'

import { ChartConfigChart } from '../../server/chartConfig'
import { Instrument, ObjectHash, Theme } from '../../types/shared'
import { SpecificChartFunctionality } from '../constants/common'
import Utils, { isRedesignedPage } from '../utils'
import LayoutModel from './chart_layout'
import { getColorOverwrites } from './chart_settings'
import { largeChartFontSize } from './chart_settings/constants'
import { INTERACTIVE_CHART_SETTINGS } from './chart_settings/interactive_chart_settings'
import { ISettings } from './chart_settings/interfaces'
import {
  IChartThemeColors,
  chartDarkTheme,
  chartLightTheme,
  elementDarkTheme,
  elementLightTheme,
  indicatorDarkTheme,
  indicatorLightTheme,
} from './constants'

interface Config {
  theme: Theme
  barMargin?: number
  barWidth?: number
  border?: number
  charts?: ChartConfigChart[]
  colorsOverride?: Array<string>
  volumeHeight?: number
  colors?: ObjectHash<Partial<IChartThemeColors>, Theme>
  specificChartFunctionality?: SpecificChartFunctionality
  isOurSiteRequest?: boolean
}

export function getSettings(config: Config, chartSettings?: ISettings): ISettings {
  const settings = merge({}, chartSettings ?? INTERACTIVE_CHART_SETTINGS(config.theme))

  if (config.barWidth != null) {
    settings.ChartSettings.center.barWidth = config.barWidth
    settings.IndicatorSettings.center.barWidth = config.barWidth
  }
  if (config.barMargin != null) {
    settings.ChartSettings.center.barMargin = config.barMargin
    settings.IndicatorSettings.center.barMargin = config.barMargin
  }
  if (config.border != null) {
    settings.ChartSettings.center.border = config.border
    settings.IndicatorSettings.center.border = config.border
  }
  if (config.colorsOverride) {
    settings.ColorsSettings = config.colorsOverride
  }
  if (config.volumeHeight) {
    settings.ChartSettings.center.volumeHeight = config.volumeHeight
  }
  if (config.colors) {
    const settingColors = settings.ChartSettings.general.Colors
    settings.ChartSettings.general.Colors = {
      ...settingColors,
      [Theme.light]: {
        ...settingColors[Theme.light],
        ...getColorOverwrites({ theme: Theme.light, colors: config.colors }),
      },
      [Theme.dark]: {
        ...settingColors[Theme.dark],
        ...getColorOverwrites({ theme: Theme.dark, colors: config.colors }),
      },
    }
  }
  if (config.specificChartFunctionality === SpecificChartFunctionality.chartPage && Utils.isMobile()) {
    settings.ChartSettings.left.width = 0
    settings.ChartSettings.top.ticker.margin.left = settings.ChartSettings.left.overlayLabel.margin.left
    settings.IndicatorSettings.left.width = 0
  }
  if (SpecificChartFunctionality.chartPage === config.specificChartFunctionality || isRedesignedPage(config)) {
    settings.ChartSettings.top.height = 10
  }

  if (config.specificChartFunctionality === SpecificChartFunctionality.offScreen) {
    const isGroupInstrument = config.charts?.some(({ instrument }) => instrument === Instrument.Group)
    if (isGroupInstrument) {
      settings.ChartSettings.top.ohlc.margin.top = 4
      settings.ChartSettings.top.ticker.margin.top = 0
      settings.ChartSettings.top.ticker.font = {
        size: largeChartFontSize.XXL,
        weight: 'bold',
      }
      settings.ChartSettings.left.timeframe.margin.top = 12
      settings.ChartSettings.left.timeframe.font = {
        size: largeChartFontSize.S,
        weight: 'bold',
      }
    }
  }

  if (config.isOurSiteRequest !== undefined) {
    settings.isOurSiteRequest = config.isOurSiteRequest
  }

  return settings
}

export function updateSettings(chart_layout: LayoutModel, theme: string): ISettings {
  const settings = merge({}, chart_layout.settings)
  const isLight = theme === Theme.light
  settings.MarketSentimentSettings.general.Colors = isLight ? { ...chartLightTheme } : { ...chartDarkTheme }
  settings.IndicatorSettings.general.Colors = isLight ? { ...indicatorLightTheme } : { ...indicatorDarkTheme }
  settings.ElementSettings.Colors = isLight ? { ...elementLightTheme } : { ...elementDarkTheme }

  return settings
}

export function getChartLayoutSettings(chart_layout: LayoutModel): ISettings {
  const settings = chart_layout.settings

  return {
    ...settings,
    ChartSettings: {
      ...settings.ChartSettings,
      general: {
        ...settings.ChartSettings.general,
        Colors: {
          ...settings.ChartSettings.general.Colors,
          ...settings.ChartSettings.general.Colors[chart_layout.theme],
        },
      },
    },
  }
}
