import { ChartConfigChartPaneElement, RequireByKey } from '../../types/shared'
import { UltCalculation } from '../indicator-calculation/ult'
import { getParsedIntegersFromPeriodString } from '../utils/helpers'
import { Attrs, UltConfig } from './configs/ult'
import Indicator from './indicator'

class UltimateOscillator extends Indicator<Attrs> {
  static config = UltConfig

  static getNumOfBarsBuffer({ period }: RequireByKey<ChartConfigChartPaneElement, 'period'>) {
    const [period1 = 0, period2 = 0, period3 = 0] = getParsedIntegersFromPeriodString(period)
    return Math.max(period1, period2, period3)
  }

  declare period1: number
  declare period2: number
  declare period3: number
  declare ultCalculation: UltCalculation

  set(values: Partial<Attrs>) {
    if (typeof values.period === 'string') {
      const [period1 = 0, period2 = 0, period3 = 0] = getParsedIntegersFromPeriodString(values.period)
      super.set({ ...values, period1, period2, period3 })
    } else {
      super.set(values)
    }
  }

  compute() {
    if (!this.isComputeNecessary()) return

    this.ultCalculation = new UltCalculation({
      quote: this.data,
      options: { period1: this.period1, period2: this.period2, period3: this.period3 },
    })
    this.ultCalculation.calculate()

    this.lastValue = this.ultCalculation.calculatedValues.ult.last() ?? null
    const { min, max } = this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getValueLabelsAtIndex(index: number) {
    return this.getOversoldOverboughtValueLabelsAtIndex(index, this.ultCalculation.calculatedValues.ult)
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    this.renderOversoldOverbought(
      context,
      this.ultCalculation.calculatedValues.ult,
      Math.max(this.period1, this.period2, this.period3),
      30,
      50,
      70
    )
  }

  getModalConfig() {
    const options = {
      period1: {
        type: 'number',
        label: 'Period 1',
        name: 'period1',
        value: this.period1 ?? 7,
        required: true,
        min: 1,
        max: 999999,
      },
      period2: {
        type: 'number',
        label: 'Period 2',
        name: 'period2',
        value: this.period2 ?? 14,
        required: true,
        min: 1,
        max: 999999,
      },
      period3: {
        type: 'number',
        label: 'Period 3',
        name: 'period3',
        value: this.period3 ?? 28,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: UltConfig.label,
      inputs: UltConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period1: `${options.period1.label} must be a whole number between ${options.period1.min} and ${options.period1.max}`,
        period2: `${options.period2.label} must be a whole number between ${options.period2.min} and ${options.period2.max}`,
        period3: `${options.period3.label} must be a whole number between ${options.period3.min} and ${options.period3.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period1':
      case 'period2':
      case 'period3':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default UltimateOscillator
