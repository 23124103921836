import { Attrs, StofuConfig } from '../indicators/configs/stofu'
import { MainCalculation } from './main'

type DefaultCalculatedValuesType = {
  k: number[]
  d: number[]
}

export function getFirstKAndDIndex({ period, dPeriod, kPeriod }: { period: number; dPeriod: number; kPeriod: number }) {
  const firstKIndex = period - 2 + kPeriod
  return { firstKIndex, firstDIndex: firstKIndex + dPeriod - 1 }
}

export class StofuCalculation extends MainCalculation<Attrs, DefaultCalculatedValuesType> {
  static config = StofuConfig

  calculate() {
    const { period, dPeriod, kPeriod } = this.options
    const { high, low, close } = this.quote

    this._calculatedValues = this.getDefaultCalculatedValues()
    const { firstKIndex, firstDIndex } = getFirstKAndDIndex({ period: period, kPeriod, dPeriod })

    if (firstDIndex < firstKIndex || [period, dPeriod, kPeriod].some((i) => i > close.length - 1)) return

    let dCount, dSum, k
    const k1Arr = []
    const dArr = []
    const kArr = []

    let kSum = (dSum = 0)
    let kCount = (dCount = 0)
    for (let i = 0; i < close.length; i++) {
      let highestHigh = high[i]
      let lowestLow = low[i]

      for (let j = i - period + 1; j <= i; j++) {
        if (high[j] > highestHigh) {
          highestHigh = high[j]
        }
        if (low[j] < lowestLow) {
          lowestLow = low[j]
        }
      }

      k = ((close[i] - lowestLow) / (highestHigh - lowestLow)) * 100
      if (highestHigh === lowestLow) {
        k = 100
      }
      k1Arr[i] = k
      if (kPeriod > 1) {
        kSum += k
        kCount++
        if (kCount > kPeriod) {
          kSum -= k1Arr[i - kPeriod]
          kCount--
        }
        k = kSum / kCount
      }

      dSum += k
      dCount++
      if (dCount > dPeriod) {
        dSum -= kArr[i - dPeriod]
        dCount--
      }

      kArr[i] = k
      dArr[i] = dSum / dCount

      if (i >= firstKIndex) {
        this._calculatedValues.k[i] = kArr[i]
        if (i >= firstDIndex) {
          this._calculatedValues.d[i] = dArr[i]
        }
      }
    }
  }
}
