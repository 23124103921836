import Line from '../canvas/line'
import { RVOLCalculation } from '../indicator-calculation/rvol'
import { getBarWidthWithMarginByParts } from '../utils/chart'
import { getHEXWithSpecificAplha } from '../utils/colors'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { Attrs, RVOLConfig } from './configs/rvol'
import Indicator from './indicator'

const DEFAULT_PARAMETERS = {
  Period: 50,
  BaseLine: 1.0,
}

function parsePeriod(periodStr: string) {
  const values = periodStr.split(',')
  const period = parseInt(values[0]) || DEFAULT_PARAMETERS.Period
  const baseLine = parseFloat(values[1]) || DEFAULT_PARAMETERS.BaseLine

  return [period, baseLine]
}

class RelativeVolume extends Indicator<Attrs> {
  static config = RVOLConfig

  declare rvolCalculation: RVOLCalculation
  declare period: number
  declare baseLine: number

  set(values: Partial<Attrs>) {
    if (typeof values.period === 'string') {
      const [period = 0, baseLine = 0] = parsePeriod(values.period)
      super.set({ ...values, period, baseLine })
    } else {
      super.set(values)
    }
  }

  compute() {
    if (this.isComputeNecessary()) {
      this.rvolCalculation = new RVOLCalculation({
        quote: this.data,
        options: { period: this.period, baseLine: this.baseLine },
      })

      this.rvolCalculation.calculate()

      this.lastValue = this.rvolCalculation.calculatedValues.rvol.last() ?? null
    }

    const { min, max } =
      this.rvolCalculation.calculatedValues.rvol.length > 0
        ? this.computeVisibleMinMax([...this.rvolCalculation.calculatedValues.rvol, this.baseLine])
        : this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getValueLabelsAtIndex(index: number) {
    const { Colors } = this.getChartLayoutSettings().ChartSettings.general
    const dataIndex = this.data.barToDataIndex[index]
    const value = this.rvolCalculation.calculatedValues.rvol[dataIndex]
    return [
      { color: value < this.baseLine ? Colors.volumeTrendDown : Colors.volumeTrendUp, text: this.getValueLabel(value) },
    ]
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const chartModel = this.model.chart()
    const { zoomFactor } = chartModel
    const { barFillWidth, barBorderWidth } = getBarWidthWithMarginByParts({
      zoomFactor,
      chartLayout: chartModel.chart_layout(),
    })
    const barWidthWithoutMargin = barFillWidth + barBorderWidth * 2
    const halfBarWidth = ~~Math.max(1, barWidthWithoutMargin / 2)

    drawInVisibleArea({
      quote: this.data,
      paneModel: this.model,
      leftOffset: this.leftOffset,
      width: this.width,
      fromIndexOffset: this.period - 1,
      drawBarCallback: (i, x) => {
        if (this.rvolCalculation.calculatedValues.rvol[i] === undefined) return

        const baseLine = Math.round(this.fy(this.baseLine))
        const y = Math.round(this.fy(this.rvolCalculation.calculatedValues.rvol[i]))

        context.set(
          'fillStyle',
          this.rvolCalculation.calculatedValues.rvol[i] < this.baseLine ? Colors.volumeTrendDown : Colors.volumeTrendUp
        )
        context.fillRect(x - halfBarWidth, baseLine, barWidthWithoutMargin, y - baseLine)
      },
    })

    const baseLineY = this.fy(this.baseLine)
    new Line(
      {
        x1: -this.leftOffset,
        x2: -this.leftOffset + this.contentWidth,
        y1: Math.round(baseLineY),
        y2: Math.round(baseLineY),
        strokeStyle: getHEXWithSpecificAplha(Colors.zeroChange, 0.5),
      },
      this.model
    ).render(context)
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? DEFAULT_PARAMETERS.Period,
        required: true,
        min: 1,
        max: 999999,
      },
      baseLine: {
        type: 'number',
        label: 'Baseline',
        name: 'baseLine',
        value: this.baseLine ?? DEFAULT_PARAMETERS.BaseLine,
        required: true,
        step: 0.1,
        allowDecimal: true,
        min: 0,
        max: 999999,
      },
    }

    return {
      title: RVOLConfig.label,
      inputs: RVOLConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
        baseLine: `${options.baseLine.label} must be a number between ${options.baseLine.min} and ${options.baseLine.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      case 'baseLine':
        return this.getIsNumberInputValid({ key, integerOnly: false })
      default:
        return false
    }
  }

  toString() {
    return `${super.toString()}, SMA`
  }
}

export default RelativeVolume
