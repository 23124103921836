import { Maybe, RequireByKey, ResizeByThumbFuncProps } from '../../types/shared'
import { CanvasElementType, TextBaseline } from '../constants/common'
import math from '../helpers/math'
import Chart from '../models/chart'
import PaneModel from '../models/pane'
import ScaledLine, { IScaledLineAttrs } from './scaled_line'
import Text from './text'

const DEFAULT_DECIMAL_PLACES = 2

class HorizontalLine<Attrs extends IScaledLineAttrs = IScaledLineAttrs> extends ScaledLine<Attrs> {
  static type = CanvasElementType.horizontalLine

  name = 'Horizontal line'
  decimalPlaces = DEFAULT_DECIMAL_PLACES
  declare scaled: Pick<Attrs, 'x1' | 'x2' | 'y1' | 'y2'>
  declare model: PaneModel

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)

    this.updateDecimalPlaces()

    if (this.attrs.y1) {
      this.attrs.y1 = Number(math.round({ value: this.attrs.y1, overridePlaces: this.decimalPlaces }))
    }
    if (this.modalConfig) {
      const step = `0.${'0'.repeat(this.decimalPlaces - 1)}1`
      this.modalConfig.inputs[1] = {
        ...this.modalConfig.inputs[1],
        step,
        min: Number(step),
      }
    }
    this.renderContent = this.renderContent.bind(this)
    this._thumbs = []
  }

  getBoundingPointKeys = () => ({ x: ['x1', 'x2'], y: ['y1', 'y2'] })

  updateDecimalPlaces() {
    const quote = this.model.chart().quote()
    this.decimalPlaces = quote
      ? math.getInstrumentDecimalPlaces({
          instrument: quote.instrument,
          value: quote.lastClose,
        })
      : DEFAULT_DECIMAL_PLACES
  }

  renderContent(context: CanvasRenderingContext2D) {
    const { ChartSettings } = this.getChartLayoutSettings()
    this.attrs.y2 = this.attrs.y1
    // mixing scaled and unscaled values, do not copy paste as solution
    this.attrs.x1 = ChartSettings.left.width * -1
    this.attrs.x2 =
      this.model
        .chart()
        .quote()
        .getBarIndex(this.model.chart().quote().close.length - 1) + this.model.chart().width
    super.renderContent(context)
  }

  renderLabels(context: CanvasRenderingContext2D) {
    if (this.attrs.y1) {
      this.updateDecimalPlaces()
      const { ChartSettings, IndicatorSettings } = this.getChartLayoutSettings()
      const isIndicatorPane = this.model
        .elements()
        .all()
        .some((element) => element.isIndicator())
      const settings = isIndicatorPane ? IndicatorSettings : ChartSettings
      context.translate(this.model.chart().width - settings.right.width, settings.top.height)
      new Text(
        {
          x: 6,
          y: this.scaled.y1,
          font: { size: 8, weight: 'bold' },
          lineHeight: 8,
          padding: {
            top: 2,
            right: 2,
            bottom: 2,
            left: 2,
          },
          fillStyle: '#fff',
          background: '#000',
          textBaseline: TextBaseline.middle,
          text: this.attrs.y1.toFixed(this.decimalPlaces).toString(),
        },
        this.model
      ).render(context)
    }
  }

  moveBy(x: Maybe<number>, y: number) {
    this.attrs.y1 += y
  }

  resize({ difY }: RequireByKey<ResizeByThumbFuncProps, 'difY'>) {
    this.moveBy(0, difY)
  }

  getIsInChartView(chart: Chart) {
    return super.getIsInChartView(chart, { omitX: true })
  }
}

HorizontalLine.prototype.modalConfig = {
  inputs: [
    { type: 'line', name: 'border' },
    { type: 'number', name: 'y1', label: 'Price', allowDecimal: true },
  ],
}

export default HorizontalLine
