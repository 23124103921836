import { useDialogState } from '@finviz/website'
import * as dateFns from 'date-fns'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  ChartElementType,
  ChartsOrQuotePageQueryParams,
  ChartsPageQueryParams,
  QuotePageQueryChartTypes,
  QuotePageQueryParams,
  SpecificChartFunctionality,
  TIMEFRAME,
} from '../../constants/common'
import Chart from '../../models/chart'
import ChartLayoutModel from '../../models/chart_layout'
import { getRawTickerForInstrument } from '../../models/utils'
import utils from '../../utils'
import { getInstrument, handleTypeChange } from '../../utils/chart'
import { getUuid } from '../../utils/helpers'
import { initHlc } from '../../utils/hlc'
import { decodeQueryString, encodeQueryString } from '../../utils/query_string'
import { IdeaLeaveDialog } from '../autosave/idea-leave-dialog'
import { IdeaDrawingsConfirmationOptions } from '../autosave/idea-leave-dialog'
import { useDrawingAutoSaveApi } from '../autosave/use-drawing-autosave-api'
import { getTickersAndContainerTypesInLayoutModel, handleCallMethodOnAllDrawings } from '../autosave/utils'
import { getChartElementTypeFromResponseType } from '../utils'

const getChartElementTypeFromQueryType = (queryType: QuotePageQueryChartTypes | null) => {
  switch (queryType) {
    case QuotePageQueryChartTypes.l:
      return ChartElementType.LineChart
    case QuotePageQueryChartTypes.hc:
      return ChartElementType.HollowCandleStick
    case QuotePageQueryChartTypes.ha:
      return ChartElementType.HeikinAshi
    case QuotePageQueryChartTypes.o:
      return ChartElementType.OhlcChart
    case QuotePageQueryChartTypes.p:
      return ChartElementType.PerfChart
    case QuotePageQueryChartTypes.c:
    case QuotePageQueryChartTypes.cs:
      return ChartElementType.CandleStick
    default:
      return (
        getChartElementTypeFromResponseType(window.FinvizSettings?.TA?.style ?? null) ?? ChartElementType.CandleStick
      )
  }
}

const useChartRouteChange = (chartLayoutModel: ChartLayoutModel | null) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { deleteAllDrawings } = useDrawingAutoSaveApi()
  const dialog = useDialogState()
  const isIdeasDialogOpen = dialog.useState('open')

  const isQuoteOrQuoteFinancials = [
    SpecificChartFunctionality.quotePage,
    SpecificChartFunctionality.quoteFinancials,
  ].includes(chartLayoutModel?.specificChartFunctionality as SpecificChartFunctionality)
  const isChartPage = chartLayoutModel?.specificChartFunctionality === SpecificChartFunctionality.chartPage
  const isFuturesForexCryptoPage = [
    SpecificChartFunctionality.futuresPage,
    SpecificChartFunctionality.forexPage,
    SpecificChartFunctionality.cryptoPage,
  ].includes(chartLayoutModel?.specificChartFunctionality as SpecificChartFunctionality)

  React.useEffect(() => {
    if (isChartPage) {
      utils.setCookie(
        'chartsUrl',
        `?${encodeQueryString({ ...decodeQueryString(location.search), i: undefined, settings: undefined })}`,
        dateFns.add(new Date(), { months: 1 })
      )
    }
  }, [isChartPage, location.search])

  React.useEffect(() => {
    const decodedQueryString = decodeQueryString<ChartsOrQuotePageQueryParams>(location.search)
    // We can navigate to idea chart without a refresh e.g. using browser back button
    // It breaks charts because we don't have idea data & idea quote anymore
    // So if we end up on a page with idea in the url and we don't have any idea data in chartLayout model we'll refresh the page
    if (chartLayoutModel && decodedQueryString.i && !chartLayoutModel.idea?.id) {
      window.location.reload()
    }
    // No need to have chartLayoutModel as dependecny here , I'm only interested in idea on the model
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, chartLayoutModel?.idea?.id])

  const updateChartConfig = React.useCallback(() => {
    if (!chartLayoutModel) return

    const decodedSearch = decodeQueryString<ChartsOrQuotePageQueryParams>(location.search)

    const urlType = (decodedSearch as QuotePageQueryParams).ty ?? null
    handleTypeChange({
      type: getChartElementTypeFromQueryType(urlType),
      chartLayoutModel,
    })

    if (isChartPage && chartLayoutModel.layout !== (decodedSearch as ChartsPageQueryParams).l) {
      chartLayoutModel.updateAttributes({ layout: (decodedSearch as ChartsPageQueryParams).l })
    }

    chartLayoutModel.getAllCharts().forEach((chartModel, chartIndex) => {
      const urlTicker = decodedSearch.t.split(',')[chartIndex] ?? null
      const urlTimeframe = decodedSearch.p?.split(',')[chartIndex] ?? null
      const urlDateRange = decodedSearch.r?.split(',')[chartIndex] ?? null
      const upperCaseTicker = urlTicker?.toUpperCase()

      /**
       * Only Charts page charts can change instruments. Other pages need forced
       * instrument because we might not be able to guess it from urlTicker
       */
      let instrument = getInstrument(upperCaseTicker)
      if (chartLayoutModel.specificChartFunctionality !== SpecificChartFunctionality.chartPage) {
        instrument = chartModel.instrument
      }

      const newChartConfig = {
        instrument,
        ticker: getRawTickerForInstrument(instrument, upperCaseTicker),
        timeframe: urlTimeframe,
        dateRange: urlDateRange,
      }

      if (Object.entries(newChartConfig).some(([key, value]) => chartModel[key as keyof Chart] !== value)) {
        chartModel.updateAttributesAndSync(newChartConfig)
      }
    })
  }, [chartLayoutModel, location.search, isChartPage])

  React.useEffect(() => {
    const decodedSearch = decodeQueryString<ChartsOrQuotePageQueryParams>(location.search)

    if (
      !chartLayoutModel ||
      !decodedSearch.p ||
      !decodedSearch.t ||
      !(isQuoteOrQuoteFinancials || isChartPage || isFuturesForexCryptoPage)
    ) {
      if (!decodedSearch.p && isQuoteOrQuoteFinancials) {
        navigate(
          {
            search: `?${encodeQueryString({
              ...decodedSearch,
              p: window.FinvizSettings.defaultPeriod ?? TIMEFRAME.d,
              r: window.FinvizSettings.defaultDateRange,
            })}`,
          },
          {
            replace: true,
          }
        )
      }
      return
    }
    if (chartLayoutModel.idea?.id && isIdeasDialogOpen) {
      dialog.hide()
    }

    if (!decodedSearch.i && chartLayoutModel.idea?.id) {
      const urlTicker = decodedSearch.t.split(',')
      const isSameTicker = chartLayoutModel
        .getAllCharts()
        .every((chart, i) => chart.getQuoteRawTicker() === urlTicker[i])

      if (isSameTicker && window.FinvizSettings.hasUserPremium && chartLayoutModel.isPreserveDrawingsActive) {
        dialog.show()
        return
      }
      if (!chartLayoutModel.isPreserveDrawingsActive || !isSameTicker) {
        handleCallMethodOnAllDrawings({ chartLayoutModel, elementMethod: 'destroyCascade' })
      }
      chartLayoutModel.exitIdea()
    }
    updateChartConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location.search,
    navigate,
    chartLayoutModel,
    isQuoteOrQuoteFinancials,
    isChartPage,
    isFuturesForexCryptoPage,
    updateChartConfig,
  ])

  const handleIdeaLeave = React.useCallback(
    async (action: IdeaDrawingsConfirmationOptions) => {
      if (!chartLayoutModel) return
      if (action === IdeaDrawingsConfirmationOptions.Cancel) {
        navigate(-1)
        return
      }
      switch (action) {
        case IdeaDrawingsConfirmationOptions.Merge: {
          handleCallMethodOnAllDrawings({
            chartLayoutModel,
            elementMethod: 'refreshElementId',
          })
          break
        }
        case IdeaDrawingsConfirmationOptions.Override: {
          const { tickers } = getTickersAndContainerTypesInLayoutModel(chartLayoutModel)
          await deleteAllDrawings({ tickers, lastLocalChange: initHlc(getUuid(), Date.now()) })
          break
        }
        case IdeaDrawingsConfirmationOptions.Nothing: {
          handleCallMethodOnAllDrawings({
            chartLayoutModel,
            elementMethod: 'destroyCascade',
          })
          break
        }
        default:
          break
      }
      chartLayoutModel.exitIdea()
      updateChartConfig()
    },
    [chartLayoutModel, updateChartConfig, deleteAllDrawings, navigate]
  )

  return {
    dialog,
    handleIdeaLeave,
  }
}

function getChartLayoutWithChartRouteChange<P extends { chartLayoutModel: ChartLayoutModel }>(
  Component: React.ComponentType<P>
) {
  return function WrappedComponent(props: P) {
    const { dialog, handleIdeaLeave } = useChartRouteChange(props.chartLayoutModel)
    return (
      <>
        <IdeaLeaveDialog state={dialog} actionCallback={handleIdeaLeave} />
        <Component {...props} />
      </>
    )
  }
}

export function withChartRouteChange<P extends { chartLayoutModel: ChartLayoutModel }>(
  Component: React.ComponentType<P>
) {
  // Chart layout is used in ReactRouter context and also outside of RR context
  // if we use RR hooks outside the context it will crash so this function will return different component for quote & chart pages than for other usecases
  const ChartLayoutComponent = getChartLayoutWithChartRouteChange(Component)
  return function WrappedComponent(props: P) {
    const { ty } = decodeQueryString<QuotePageQueryParams>()
    const isChartPage = props.chartLayoutModel.specificChartFunctionality === SpecificChartFunctionality.chartPage
    const isQuotePage = props.chartLayoutModel.specificChartFunctionality === SpecificChartFunctionality.quotePage
    const isQuoteFinancials =
      props.chartLayoutModel.specificChartFunctionality === SpecificChartFunctionality.quoteFinancials
    const isFuturesForexCryptoPage = [
      SpecificChartFunctionality.futuresPage,
      SpecificChartFunctionality.forexPage,
      SpecificChartFunctionality.cryptoPage,
    ].includes(props.chartLayoutModel.specificChartFunctionality)
    if (
      isChartPage ||
      (isQuotePage && ty !== QuotePageQueryChartTypes.p) ||
      isQuoteFinancials ||
      isFuturesForexCryptoPage
    ) {
      return <ChartLayoutComponent {...props} />
    }

    return <Component {...props} />
  }
}
