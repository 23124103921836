import { OverlayType } from '../../constants/common'
import BaseConfig from './overlayBaseConfig'

export enum PPCalculationTypeEnum {
  standard,
  fibonacci,
}

export const PPCalculationTypeLabels = {
  [PPCalculationTypeEnum.standard]: 'Standard',
  [PPCalculationTypeEnum.fibonacci]: 'Fibonacci',
}

export type Options = {
  calculationType: PPCalculationTypeEnum
}
export interface Attrs extends Options {
  period?: string
  color: string
}

export class PPConfig extends BaseConfig {
  static type = OverlayType.Pp
  static abbreviation = 'pp'
  static label = 'Pivot Points'
  static shortLabel = 'Pivot Points'
  static optionsOrder = ['calculationType'] as const
  static optionsLabels = { calculationType: 'Calculation Type' }
  static inputsOrder = ['calculationType', 'color'] as Array<keyof Omit<Attrs, 'period'>>
  static inputsLabels = { calculationType: 'Calculation Type', color: 'Color' }
  static calculatedValuesOrder = ['pivot', 'res1', 'res2', 'res3', 'sup1', 'sup2', 'sup3'] as const

  static calculatedValuesLabels = {
    pivot: 'Pivot',
    res1: 'Resistance 1',
    res2: 'Resistance 2',
    res3: 'Resistance 3',
    sup1: 'Support 1',
    sup2: 'Support 2',
    sup3: 'Support 3',
  }

  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
