import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export type Options = {
  period: number
  momentum: number
}

export type Attrs = Options

export class RMIConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Rmi
  static abbreviation = 'rmi'
  static label = 'Relative Momentum Index'
  static shortLabel = 'RMI'
  static optionsOrder = ['period', 'momentum'] as const
  static optionsLabels = { period: 'Period', momentum: 'Momentum' }
  static inputsOrder = this.optionsOrder
  static inputsLabels = this.optionsLabels
  static calculatedValuesOrder = ['rmi'] as const
  static calculatedValuesLabels = { rmi: 'RMI' }
  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
