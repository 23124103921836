import Line from '../canvas/line'
import { StofuCalculation, getFirstKAndDIndex } from '../indicator-calculation/stofu'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { getParsedIntegersFromPeriodString } from '../utils/helpers'
import { Attrs, StofuConfig } from './configs/stofu'
import Indicator from './indicator'

class Stochastics<T extends Attrs = Attrs> extends Indicator<T> {
  static config = StofuConfig

  declare kPeriod: number
  declare period: number
  declare dPeriod: number
  declare stofuCalculation: StofuCalculation
  declare firstKIndex: number
  declare firstDIndex: number

  set(values: Partial<T>) {
    if (typeof values.period === 'string') {
      const [period = 0, dPeriod = 0, kPeriod = 0] = getParsedIntegersFromPeriodString(values.period)
      super.set({ ...values, period, dPeriod, kPeriod })
    } else {
      super.set(values)
    }
  }

  compute() {
    if (!this.isComputeNecessary()) return

    this.stofuCalculation = new StofuCalculation({
      quote: this.data,
      options: { period: this.period, dPeriod: this.dPeriod, kPeriod: this.kPeriod },
    })

    this.stofuCalculation.calculate()

    const { firstKIndex, firstDIndex } = getFirstKAndDIndex({
      period: this.period,
      kPeriod: this.kPeriod,
      dPeriod: this.dPeriod,
    })
    this.firstKIndex = firstKIndex
    this.firstDIndex = firstDIndex

    this.lastValue = this.stofuCalculation.calculatedValues.k.last() ?? null
    const { min, max } = this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getLineColors() {
    return {
      k: this.getChartLayoutSettings().IndicatorSettings.general.Colors.line,
      d: '#c80000',
    }
  }

  getValueLabelsAtIndex(index: number) {
    const lineColors = this.getLineColors()
    const dataIndex = this.data.barToDataIndex[index]
    return [
      { color: lineColors.k, text: this.getValueLabel(this.stofuCalculation.calculatedValues.k[dataIndex]) },
      { color: lineColors.d, text: this.getValueLabel(this.stofuCalculation.calculatedValues.d[dataIndex]) },
    ]
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    if (this.data.close.length === 0) return

    new Line(
      {
        x1: -this.leftOffset,
        x2: -this.leftOffset + this.contentWidth,
        y1: Math.round(this.fy(50)),
        y2: Math.round(this.fy(50)),
        strokeStyle: '#ff8787',
        dashLength: 3,
      },
      this.model
    ).render(context)
    new Line(
      {
        x1: -this.leftOffset,
        x2: -this.leftOffset + this.contentWidth,
        y1: Math.round(this.fy(20)),
        y2: Math.round(this.fy(20)),
        strokeStyle: '#69c1ea',
      },
      this.model
    ).render(context)
    new Line(
      {
        x1: -this.leftOffset,
        x2: -this.leftOffset + this.contentWidth,
        y1: Math.round(this.fy(80)),
        y2: Math.round(this.fy(80)),
        strokeStyle: '#d386df',
      },
      this.model
    ).render(context)

    const lineColors = this.getLineColors()
    context.set('strokeStyle', lineColors.k)
    context.translate(0.5, 0.5)
    context.beginPath()
    const drawInVisibleAreaProps = {
      quote: this.data,
      paneModel: this.model,
      leftOffset: this.leftOffset,
      width: this.width,
    }
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      fromIndexOffset: this.firstKIndex,
      drawBarCallback: (i, x) => {
        context.lineTo(x, Math.round(this.fy(this.stofuCalculation.calculatedValues.k[i])))
      },
    })
    context.stroke()

    context.set('strokeStyle', lineColors.d)
    context.beginPath()
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      fromIndexOffset: this.firstDIndex,
      drawBarCallback: (i, x) => {
        context.lineTo(x, Math.round(this.fy(this.stofuCalculation.calculatedValues.d[i])))
      },
    })
    context.stroke()
    context.translate(-0.5, -0.5)
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 14,
        required: true,
        min: 1,
        max: 999999,
      },
      kPeriod: {
        type: 'number',
        label: 'K Period',
        name: 'kPeriod',
        value: this.kPeriod ?? 3,
        required: true,
        min: 1,
        max: 999999,
      },
      dPeriod: {
        type: 'number',
        label: 'D Period',
        name: 'dPeriod',
        value: this.dPeriod ?? 3,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: StofuConfig.label,
      inputs: StofuConfig.inputsOrder.map((item) => options[item as keyof Attrs]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
        dPeriod: `${options.dPeriod.label} must be a whole number between ${options.dPeriod.min} and ${options.dPeriod.max}`,
        kPeriod: `${options.kPeriod.label} must be a whole number between ${options.kPeriod.min} and ${options.kPeriod.max}`,
      } as {
        period: string
        dPeriod: string
        kPeriod?: string
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
      case 'dPeriod':
      case 'kPeriod':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default Stochastics
