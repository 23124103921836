import { ObjectHash, Theme } from '../../../types/shared'
import { TextAlign, TextBaseline } from '../../constants/common'
import { IChartThemeColors, IElementThemeColors, IIndicatorThemeColors } from '../constants'

export enum OHLCType {
  singleLine = 'singleLine',
  dateOnly = 'dateOnly',
  timeOnly = 'timeOnly',
}

export interface IMargin {
  top?: number
  right?: number
  bottom?: number
  left?: number
}

interface IPadding {
  top?: number
  right?: number
  bottom?: number
  left?: number
}

interface IGenericText {
  margin: IMargin
  font: IFont
}

interface IFont {
  size?: number
  style?: 'normal' | 'italic'
  weight?: 'normal' | 'bold' | '900'
  family?: string
  lineHeight?: number
  padding?: IPadding
  baseline?: TextBaseline
  textAlign?: TextAlign
}

interface ISettingsGeneralBase {
  Colors: IChartThemeColors
}

export interface ISettingsCenterBase {
  barWidth: number
  barMargin: number
  border: number
  volumeHeight?: number
}

interface ISettingsTopBase {
  height: number
}

interface ISettingsBottomBase {
  height: number
}

interface ISettingsLeftBase {
  width: number
}

interface ISettingsRightBase {
  width: number
}

export interface ISettings {
  ChartSettings: IChartSettings
  IndicatorSettings: IIndicatorSettings
  MarketSentimentSettings: IMarketSentimentChartSettings
  ColorsSettings: Array<string>
  ThumbSettings: IThumbSettings
  ElementSettings: IElementSettings
  isOurSiteRequest?: boolean
}

export interface IChartSettings {
  general: IChartGeneral
  center: ISettingsCenterBase
  top: IChartTop
  bottom: IChartBottom
  left: IChartLeft
  right: IChartRight
}

interface IChartGeneral {
  settingsHeight: number
  tickerTimeframeChangeHeight: number
  Colors: IChartThemeColors & ObjectHash<IChartThemeColors, Theme>
}

interface IChartTop extends ISettingsTopBase {
  baseline: TextBaseline
  ticker: IGenericText
  ohlc: IOHLC
  change: IGenericText
}

interface IOHLC extends IGenericText {
  type?: OHLCType
}

interface IChartBottom extends ISettingsBottomBase {
  baseline: TextBaseline
  axis: IGenericText
}

interface IChartLeft extends ISettingsLeftBase {
  overlayLabel: IOverlayLabel
  timeframe: IGenericText
  volumeAxis: IGenericText
}

interface IOverlayLabel extends IGenericText {
  labelSpacing: number
}

interface IChartRight extends ISettingsRightBase {
  axis: IGenericText
}

export interface IIndicatorSettings {
  general: IIndicatorGeneral
  center: ISettingsCenterBase
  top: ISettingsTopBase
  bottom: ISettingsBottomBase
  left: IIndicatorLeft
  right: IIndicatorRight
}

interface IIndicatorRight extends ISettingsRightBase {
  axis: IGenericText
}

interface IIndicatorLeft extends ISettingsLeftBase {
  indicatorLabel: IIndicatorLabel
}

interface IIndicatorLabel extends IGenericText {
  labelSpacing: number
}

interface IIndicatorGeneral {
  Colors: IIndicatorThemeColors
  height: number
}

interface IMarketSentimentChartSettings {
  general: ISettingsGeneralBase
  center: ISettingsCenterBase
  top: ISettingsTopBase
  bottom: ISettingsBottomBase
  left: ISettingsLeftBase
  right: ISettingsRightBase
}

interface IThumbSettings {
  size: number
  touchSize: number
  color: string
  borderWidth: number
  borderColor: string
}

interface IElementSettings {
  Colors: IElementThemeColors
  defaultFill: string
  defaultTransparentFill: string
}
