import Text from '../canvas/text'
import { OFFSET, PADDING, TextBaseline } from '../constants/common'
import { getTranslate } from '../controllers/renderUtils'
import { RWICalculation } from '../indicator-calculation/rwi'
import Pane from '../models/pane'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { Attrs, RWIConfig } from './configs/rwi'
import Indicator from './indicator'

class RWI extends Indicator<Attrs> {
  static config = RWIConfig

  declare rwiCalculation: RWICalculation
  declare period: number

  constructor(attrs: Attrs, model: Pane) {
    super(attrs, model)
    this.renderYAxis = this.renderYAxis.bind(this)
  }

  set(values: Partial<Attrs>) {
    super.set(values)
    this.parsePeriodInt(values)
  }

  compute() {
    if (this.isComputeNecessary()) {
      this.rwiCalculation = new RWICalculation({
        quote: this.data,
        options: { period: this.period },
      })

      this.rwiCalculation.calculate()
    }
    const { min, max } =
      this.rwiCalculation.calculatedValues.rwiHigh.length > 0 || this.rwiCalculation.calculatedValues.rwiLow.length > 0
        ? this.computeVisibleMinMax(
            this.rwiCalculation.calculatedValues.rwiHigh,
            this.rwiCalculation.calculatedValues.rwiLow
          )
        : this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getLineColors() {
    return {
      rwiLow: '#c80000',
      rwiHigh: '#00a000',
    }
  }

  getValueLabelsAtIndex(index: number) {
    if (this.data.close.length === 0) return []

    const lineColors = this.getLineColors()
    const dataIndex = this.data?.barToDataIndex[index]
    return [
      {
        color: lineColors.rwiLow,
        text: this.getValueLabel(this.rwiCalculation.calculatedValues.rwiLow[dataIndex]),
      },
      {
        color: lineColors.rwiHigh,
        text: this.getValueLabel(this.rwiCalculation.calculatedValues.rwiHigh[dataIndex]),
      },
    ]
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    if (this.data.close.length === 0) return

    const drawInVisibleAreaProps = {
      quote: this.data,
      paneModel: this.model,
      leftOffset: this.leftOffset,
      width: this.width,
      fromIndexOffset: this.period,
    }

    context.translate(0.5, 0.5)
    context.set('strokeStyle', '#c80000')
    context.beginPath()
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i: number, x: number) => {
        context.lineTo(x, Math.round(this.fy(this.rwiCalculation.calculatedValues.rwiLow[i])))
      },
    })
    context.stroke()

    context.set('strokeStyle', '#00a000')
    context.beginPath()
    drawInVisibleArea({
      ...drawInVisibleAreaProps,
      drawBarCallback: (i: number, x: number) => {
        context.lineTo(x, Math.round(this.fy(this.rwiCalculation.calculatedValues.rwiHigh[i])))
      },
    })
    context.stroke()
    context.translate(-0.5, -0.5)
  }

  renderYAxis(context: CanvasRenderingContext2D) {
    super.renderYAxis(context)
    const { IndicatorSettings, ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const translate = getTranslate({
      context,
      xOffset: IndicatorSettings.left.width,
      yOffset: IndicatorSettings.top.height,
    })
    translate.do()
    const text = new Text(
      {
        x: this.contentWidth + OFFSET.M - PADDING.XXS + IndicatorSettings.right.axis.margin.left!,
        font: Text.getMergedPropsWithDefaults('font', IndicatorSettings.right.axis.font),
        lineHeight: IndicatorSettings.right.axis.font.lineHeight,
        padding: {
          top: IndicatorSettings.right.axis.font?.padding?.top ?? PADDING.XXS,
          right: IndicatorSettings.right.axis.font?.padding?.right ?? PADDING.XXS,
          bottom: IndicatorSettings.right.axis.font?.padding?.bottom ?? PADDING.XXS,
          left: IndicatorSettings.right.axis.font?.padding?.left ?? PADDING.XXS,
        },
        textBaseline: TextBaseline.middle,
        fillStyle: Colors.indicatorCurrentText,
      },
      this.model
    )
    const textLabels = []
    if (this.rwiCalculation.calculatedValues.rwiLow.length > 0) {
      textLabels.push({
        text: this.rwiCalculation.calculatedValues.rwiLow.last()!.toFixed(2),
        y: Math.round(this.fy(this.rwiCalculation.calculatedValues.rwiLow.last()!)),
        background: '#c80000',
      })
    }
    if (this.rwiCalculation.calculatedValues.rwiHigh.length > 0) {
      textLabels.push({
        text: this.rwiCalculation.calculatedValues.rwiHigh.last()!.toFixed(2),
        y: Math.round(this.fy(this.rwiCalculation.calculatedValues.rwiHigh.last()!)),
        background: '#00a000',
      })
    }

    const minY = 0
    const maxY = this.height - IndicatorSettings.bottom.height - IndicatorSettings.top.height
    textLabels.forEach((labelSetting) => {
      if (labelSetting.y >= minY && labelSetting.y <= maxY) {
        text.set(labelSetting).render(context)
      }
    })

    translate.undo()
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 9,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: RWIConfig.label,
      inputs: RWIConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default RWI
