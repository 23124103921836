import { Options, UltConfig } from '../indicators/configs/ult'
import { MainCalculation } from './main'

type DefaultCalculatedValuesType = {
  ult: number[]
}

export class UltCalculation extends MainCalculation<Options, DefaultCalculatedValuesType> {
  static config = UltConfig

  calculate() {
    const { period1, period2, period3 } = this.options
    const { high, low, close } = this.quote
    const maxPeriod = Math.max(period1, period2, period3)

    this._calculatedValues = this.getDefaultCalculatedValues()

    if (close.length - 1 < maxPeriod) return

    let bpSum2, bpSum3, count2, count3, trSum2, trSum3
    const tr = []
    const bp = []
    let bpSum1 = (bpSum2 = bpSum3 = 0)
    let trSum1 = (trSum2 = trSum3 = 0)
    let count1 = (count2 = count3 = 0)
    let avg2, avg3

    for (let i = 1; i < close.length; i++) {
      bp[i] = close[i] - Math.min(low[i], close[i - 1])
      tr[i] = Math.max(high[i], close[i - 1]) - Math.min(low[i], close[i - 1])

      bpSum1 += bp[i]
      trSum1 += tr[i]
      count1++
      if (count1 > period1) {
        bpSum1 -= bp[i - period1]
        trSum1 -= tr[i - period1]
      }

      bpSum2 += bp[i]
      trSum2 += tr[i]
      count2++
      if (count2 > period2) {
        bpSum2 -= bp[i - period2]
        trSum2 -= tr[i - period2]
      }

      bpSum3 += bp[i]
      trSum3 += tr[i]
      count3++
      if (count3 > period3) {
        bpSum3 -= bp[i - period3]
        trSum3 -= tr[i - period3]
      }

      let avg1 = (avg2 = avg3 = 0)
      if (trSum1 > 0) {
        avg1 = bpSum1 / trSum1
      }
      if (trSum2 > 0) {
        avg2 = bpSum2 / trSum2
      }
      if (trSum3 > 0) {
        avg3 = bpSum3 / trSum3
      }

      if (i >= maxPeriod) this._calculatedValues.ult[i] = (100 * (4 * avg1 + 2 * avg2 + avg3)) / 7
    }
  }
}
