import { DrawingBorder, PaneArea, ResizeByThumbWithTypeAndDifs } from '../../types/shared'
import { CanvasElementType, TextAlign, TextBaseline } from '../constants/common'
import { getRoundedObject, getXYOffsetFromLine } from '../controllers/renderUtils'
import PaneModel from '../models/pane'
import Element from './element'
import Text, { ITextAttrs } from './text'
import Thumb from './thumb'

type Coefficients = string

export interface IFibonacciRetracementsAttrs {
  x1: number
  x2: number
  y1: number
  y2: number
  coefficients: Coefficients
  border: DrawingBorder
}

class FibonacciRetracements<
  Attrs extends IFibonacciRetracementsAttrs = IFibonacciRetracementsAttrs,
> extends Element<Attrs> {
  static type = CanvasElementType.fibonacciRetracements

  name = 'Fibonacci Retracements'
  declare coefficients: { default: string; parsed: number[]; max: number; min: number }
  declare scaled: Pick<Attrs, 'x1' | 'x2' | 'y1' | 'y2'>

  constructor(values: Partial<Attrs>, model: PaneModel) {
    super(values, model)
    this.resize = this.resize.bind(this)
    this._thumbs = [
      new Thumb(
        'oneCoefficient_1',
        () => this.attrs.x1,
        () => this.attrs.y1,
        this.resize,
        this.model
      ),
      new Thumb(
        'oneCoefficient_2',
        () => this.attrs.x2,
        () => this.attrs.y1,
        this.resize,
        this.model
      ),
      new Thumb(
        'zeroCoefficient_1',
        () => this.attrs.x1,
        () => this.attrs.y2,
        this.resize,
        this.model
      ),
      new Thumb(
        'zeroCoefficient_2',
        () => this.attrs.x2,
        () => this.attrs.y2,
        this.resize,
        this.model
      ),
    ]
    this.parseCoefficients(this.attrs.coefficients)
    this.scale(this.getBoundingPointKeys())
  }

  getDefaults() {
    return {
      border: {
        width: 1,
        color: this.getChartLayoutSettings().ElementSettings.Colors.line,
      },
      coefficients: [0, 0.236, 0.382, 0.5, 0.618, 1].toString(),
    } as Partial<Attrs>
  }

  getBoundingPointKeys = () => ({ x: ['x1', 'x2'], y: ['y1', 'y2'] })

  parseCoefficients(coefficients: Coefficients) {
    if (!this.coefficients || coefficients !== this.coefficients.default) {
      const parsed = coefficients.split(',').map((x) => parseFloat(x.trim()))
      this.coefficients = { default: coefficients, parsed, max: Math.max(...parsed), min: Math.min(...parsed) }
    }
  }

  getMaxCoefficientUnscaledY() {
    return this.model.scale.y.invert(this.scaled.y2 - this.coefficients.max * (this.scaled.y2 - this.scaled.y1))
  }

  getMinCoefficientUnscaledY() {
    return this.model.scale.y.invert(this.scaled.y2 - this.coefficients.min * (this.scaled.y2 - this.scaled.y1))
  }

  renderContent(context: CanvasRenderingContext2D) {
    const { x1, x2, y1, y2 } = this.scaled
    const roundedXY = getRoundedObject({ x1, x2, y1, y2 })
    const translateXY = getXYOffsetFromLine({ lineWidth: this.attrs.border.width, ...roundedXY, y2: roundedXY.y1 })

    context.set('lineWidth', this.attrs.border.width)
    context.set('strokeStyle', this.attrs.border.color)
    this.parseCoefficients(this.attrs.coefficients)

    const height = this.attrs.y2 - this.attrs.y1

    context.translate(translateXY.x, translateXY.y)
    context.beginPath()
    for (const p of this.coefficients.parsed) {
      const y = Math.round(this.fy(this.attrs.y2 - p * height))
      context.moveTo(roundedXY.x1, y)
      context.lineTo(roundedXY.x2, y)
    }
    context.stroke()
    context.translate(translateXY.x * -1, translateXY.y * -1)

    const text = new Text(
      {
        x: roundedXY.x1,
        font: { size: 8, weight: 'bold' },
        textBaseline: TextBaseline.bottom,
        fillStyle: this.attrs.border.color,
        textAlign: TextAlign.left,
      },
      this.model
    )
    if (roundedXY.x1 > roundedXY.x2) {
      text.set({ textAlign: TextAlign.right })
    }

    for (const p of this.coefficients.parsed) {
      const y = this.attrs.y2 - p * height
      text.set({
        text: (p * 100).toFixed(1) + '%: ' + y.toFixed(2),
        y: Math.round(this.fy(y) - this.attrs.border.width / 2),
      } as Partial<ITextAttrs>)
      text.render(context)
    }

    if (this.getShouldRenderThumbs()) {
      this.renderThumbs(context)
    }
  }

  getThumbs() {
    if (this.coefficients.max > 1 && !this._thumbs.some(({ type }) => type.includes('maxCoefficient'))) {
      this._thumbs = [
        ...this._thumbs,
        new Thumb(
          'maxCoefficient_1',
          () => this.attrs.x1,
          () => this.getMaxCoefficientUnscaledY(),
          this.resize,
          this.model
        ),
        new Thumb(
          'maxCoefficient_2',
          () => this.attrs.x2,
          () => this.getMaxCoefficientUnscaledY(),
          this.resize,
          this.model
        ),
      ]
    }
    if (this.coefficients.max <= 1 && this._thumbs.some(({ type }) => type.includes('maxCoefficient'))) {
      this._thumbs = this._thumbs.filter(({ type }) => !type.includes('maxCoefficient'))
    }

    if (this.coefficients.min < 0 && !this._thumbs.some(({ type }) => type.includes('minCoefficient'))) {
      this._thumbs = [
        ...this._thumbs,
        new Thumb(
          'minCoefficient_1',
          () => this.attrs.x1,
          () => this.getMinCoefficientUnscaledY(),
          this.resize,
          this.model
        ),
        new Thumb(
          'minCoefficient_2',
          () => this.attrs.x2,
          () => this.getMinCoefficientUnscaledY(),
          this.resize,
          this.model
        ),
      ]
    }
    if (this.coefficients.min >= 0 && this._thumbs.some(({ type }) => type.includes('minCoefficient'))) {
      this._thumbs = this._thumbs.filter(({ type }) => !type.includes('minCoefficient'))
    }

    return this._thumbs
  }

  moveBy(x: number, y: number) {
    this.attrs.x1 += x
    this.attrs.x2 += x
    this.attrs.y1 += y
    this.attrs.y2 += y
  }

  resize({ type, difX, difY }: ResizeByThumbWithTypeAndDifs) {
    switch (type) {
      case 'oneCoefficient_1':
        this.attrs.x1 += difX
        this.attrs.y1 += difY
        break
      case 'oneCoefficient_2':
        this.attrs.x2 += difX
        this.attrs.y1 += difY
        break
      case 'zeroCoefficient_1':
        this.attrs.x1 += difX
        this.attrs.y2 += difY
        break
      case 'zeroCoefficient_2':
        this.attrs.x2 += difX
        this.attrs.y2 += difY
        break
      case 'maxCoefficient_1':
        this.attrs.x1 += difX
        this.attrs.y1 += difY / this.coefficients.max
        break
      case 'maxCoefficient_2':
        this.attrs.x2 += difX
        this.attrs.y1 += difY / this.coefficients.max
        break
      case 'minCoefficient_1':
        this.attrs.x1 += difX
        this.attrs.y2 += difY / (Math.abs(this.coefficients.min) + 1)
        break
      case 'minCoefficient_2':
        this.attrs.x2 += difX
        this.attrs.y2 += difY / (Math.abs(this.coefficients.min) + 1)
        break
      default:
        break
    }
  }

  isInArea(area: PaneArea) {
    if (super.isDrawingElementLockedOrInvisible()) return false
    const maxCoefficientY = this.coefficients.max > 1 && this.getMaxCoefficientUnscaledY()
    const minCoefficientY = this.coefficients.min < 0 && this.getMinCoefficientUnscaledY()
    const left = Math.min(this.attrs.x1, this.attrs.x2)
    const right = Math.max(this.attrs.x1, this.attrs.x2)
    const allYValues = [this.attrs.y1, this.attrs.y2, maxCoefficientY, minCoefficientY].filter((item) => item !== false)
    const top = Math.min(...allYValues)
    const bottom = Math.max(...allYValues)

    if (left < area.x && area.x < right && top < area.y && area.y < bottom) {
      return true
    }
    return super.isInArea(area)
  }

  getEdgeXYValues() {
    this.edgeXYValues = super.getEdgeXYValues()

    // Because minY, maxY can go outside of control points boundaries [0,1] we need to compensate
    // for that in getEdgeXYValues(), otherwise getIsInChartView() hide drawings sooner than it should
    const min = this.getMinCoefficientUnscaledY()
    const max = this.getMaxCoefficientUnscaledY()

    if (!isNaN(min) && !isNaN(max)) {
      this.edgeXYValues.minY = Math.min(min, max)
      this.edgeXYValues.maxY = Math.max(min, max)
    }

    return this.edgeXYValues
  }
}

FibonacciRetracements.prototype.modalConfig = {
  inputs: [
    { type: 'line', name: 'border' },
    { type: 'string', name: 'coefficients', label: 'Coefficients' },
  ],
}

export default FibonacciRetracements
