import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export type Options = {
  period1: number
  period2: number
  period3: number
}

export interface Attrs extends Options {
  period: string
}

export class UltConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Ult
  static abbreviation = 'ult'
  static label = 'Ultimate Oscillator'
  static shortLabel = 'ULT'
  static optionsOrder = ['period1', 'period2', 'period3'] as const
  static optionsLabels = {
    period1: 'Period 1',
    period2: 'Period 2',
    period3: 'Period 3',
  }

  static inputsOrder = this.optionsOrder
  static inputsLabels = this.optionsLabels
  static calculatedValuesOrder = ['ult'] as const
  static calculatedValuesLabels = { ult: 'Ultimate Oscillator' }
  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
