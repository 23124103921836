import Line from '../canvas/line'
import { ROCCalculation } from '../indicator-calculation/roc'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { Attrs, ROCConfig } from './configs/roc'
import Indicator from './indicator'

class ROC extends Indicator {
  static config = ROCConfig

  declare rocCalculation: ROCCalculation
  declare period: number

  set(values: Partial<Attrs>) {
    super.set(values)
    this.parsePeriodInt(values)
  }

  compute() {
    if (this.isComputeNecessary()) {
      this.rocCalculation = new ROCCalculation({
        quote: this.data,
        options: { period: this.period },
      })

      this.rocCalculation.calculate()

      this.lastValue = this.rocCalculation.calculatedValues.roc.last() ?? null
    }
    const { min, max } =
      this.rocCalculation.calculatedValues.roc.length > 0
        ? this.computeVisibleMinMax(this.rocCalculation.calculatedValues.roc)
        : this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getValueLabelsAtIndex(index: number) {
    const dataIndex = this.data.barToDataIndex[index]
    return [
      {
        color: this.getChartLayoutSettings().IndicatorSettings.general.Colors.line!,
        text: this.getValueLabel(this.rocCalculation.calculatedValues.roc[dataIndex]),
      },
    ]
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    new Line(
      {
        x1: -this.leftOffset,
        x2: -this.leftOffset + this.contentWidth,
        y1: Math.round(this.fy(0)),
        y2: Math.round(this.fy(0)),
        strokeStyle: '#ff8787',
        dashLength: 3,
      },
      this.model
    ).render(context)
    context.set('strokeStyle', this.getChartLayoutSettings().IndicatorSettings.general.Colors.line)
    context.translate(0.5, 0.5)
    context.beginPath()
    drawInVisibleArea({
      quote: this.data,
      fromIndexOffset: this.period,
      paneModel: this.model,
      leftOffset: this.leftOffset,
      width: this.width,
      drawBarCallback: (i: number, x: number) => {
        context.lineTo(x, Math.round(this.fy(this.rocCalculation.calculatedValues.roc[i])))
      },
    })
    context.stroke()
    context.translate(-0.5, -0.5)
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 12,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: ROCConfig.label,
      inputs: ROCConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default ROC
