import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export type Options = {
  period: number
  dPeriod: number
  kPeriod: number
}

export type Attrs = Options

export class StofuConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Stofu
  static abbreviation = 'stofu'
  static label = 'Stochastics FULL'
  static shortLabel = 'Stochastics FULL'
  static optionsOrder = ['period', 'dPeriod', 'kPeriod']

  static optionsLabels = { period: 'Period', kPeriod: 'K Period', dPeriod: 'D Period' } as { [key: string]: string }

  static inputsOrder = this.optionsOrder
  static inputsLabels = this.optionsLabels
  static calculatedValuesOrder = ['k', 'd'] as const
  static calculatedValuesLabels = { k: '%K', d: '%D' }
  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
