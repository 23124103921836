import * as d3 from 'd3'

import { ObjectHash } from '../../types/shared'
import Line from '../canvas/line'
import Text from '../canvas/text'
import {
  INDICATOR_LABEL_HEIGHT,
  MOBILE_VIEW_BREAKPOINT_WIDTH,
  OFFSET,
  PADDING,
  SpecificChartFunctionality,
  TextAlign,
  TextBaseline,
} from '../constants/common'
import { getTranslate } from '../controllers/renderUtils'
import Pane from '../models/pane'
import Quote from '../models/quote'
import { getSanitizedTicker } from '../utils'
import { getInstrument } from '../utils/chart'
import { getHEXWithSpecificAplha } from '../utils/colors'
import { getVisibleBarToRenderIndex } from '../utils/draw_in_visible_area'
import { Attrs, PerfConfig } from './configs/perf'
import Indicator from './indicator'

const DEFAULT_TICKERS = 'SPY,QQQ'
const NO_VALUE = ' - '

type PerfData = { lastValueIndex: number; values: Array<number | null> }
function getDefaultPerfData(): PerfData {
  return {
    lastValueIndex: 0,
    values: [],
  }
}

class PricePerformance extends Indicator<Attrs> {
  static config = PerfConfig

  static getNumOfBarsBuffer() {
    return 0
  }

  timeframe: string
  tickers = ''
  ready = false
  perf: ObjectHash<PerfData> = {}
  quotes: ObjectHash<Quote> = {} // this is used in getAllQuotes() in charts/app/models/chart.ts
  indicatorLabelWidth: ObjectHash<number> = {}

  constructor(attrs: Attrs, model: Pane) {
    super(attrs, model)
    this.renderYAxis = this.renderYAxis.bind(this)

    this.timeframe = this.model.chart().timeframe
    this.fy = d3.scaleLinear().range([0, this.contentHeight])
    if (this.attrs.period && !this.tickers) {
      this.tickers = this.attrs.period
    }
  }

  async compute() {
    const data: ObjectHash<Quote> = {}
    let hasCachedData = true
    this.getTickersAndTimeframe().forEach((quoteSettings) => {
      const quote = Quote.getFromCacheSync(quoteSettings)
      hasCachedData = hasCachedData && !!quote
      if (quote) {
        data[quoteSettings.ticker] = quote
      }
    })

    if (hasCachedData) {
      return this._compute(Object.keys(data), data)
    }

    const fetchedData = await Quote.getAll(this.getTickersAndTimeframe())
    if (fetchedData) {
      this._compute(Object.keys(fetchedData), fetchedData)
      this.trigger('change')
    }
  }

  _compute(tickers: string[], data: ObjectHash<Quote>) {
    const { leftOffset } = this.model.chart()
    data[this.data.ticker] = this.data
    this.quotes = data
    this.perf = {}

    const firstVisibleBar = getVisibleBarToRenderIndex({
      quote: this.data,
      leftOffset,
      paneModel: this.model,
    })
    const lastVisibleBar = getVisibleBarToRenderIndex({
      quote: this.data,
      leftOffset,
      paneModel: this.model,
      chartWidth: this.contentWidth,
    })

    let min: number | null = null
    let max: number | null = null

    for (const ticker of [this.data.ticker, ...tickers]) {
      this.perf[ticker] = getDefaultPerfData()
      const d = data[ticker]
      const lastTickerTimestamp = d.date[d.date.length - 1]
      if (!d) {
        continue
      }
      const dateToIndex = d.getDateToIndex()
      let first = null
      let lastValue = null
      let dataIndex = -1
      for (let i = firstVisibleBar.barIndex; i <= lastVisibleBar.barIndex; i++) {
        const timestamp = this.data.getTimestampFomPositionX(i)
        dataIndex = dateToIndex[timestamp]

        if (typeof dataIndex === 'number') {
          first = first === null ? d.close[dataIndex] : first
          lastValue = (d.close[dataIndex] * 100) / (first ?? 0) - 100
        }

        this.perf[ticker].values[i] = null
        if (lastTickerTimestamp >= timestamp && lastValue !== null && !isNaN(lastValue)) {
          this.perf[ticker].values[i] = lastValue
          this.perf[ticker].lastValueIndex = i
          if (min === null || (lastValue !== null && min > lastValue)) min = lastValue
          if (max === null || (lastValue !== null && max < lastValue)) max = lastValue
        }
      }

      if (firstVisibleBar.barIndex > 0) {
        this.perf[ticker].values[firstVisibleBar.barIndex - 1] = this.perf[ticker].values[firstVisibleBar.barIndex]
      }
      if (lastVisibleBar.barIndex < this.data.barToDataIndex.length) {
        this.perf[ticker].values[lastVisibleBar.barIndex + 1] = this.perf[ticker].values[lastVisibleBar.barIndex]
      }
    }

    // > 2 because we need at least 2 bars to be able to calculate price perf and also we copy first visible bar value
    // to its index - 1 to ensure line is drawn from the very left edge of the chart
    // so first 2 values are the same and we need one more
    const canUseCustomMinMax = Object.values(this.perf).some(
      ({ values }) => values.filter((val) => typeof val === 'number').length > 2
    )
    const domainDefaults = this.getDomainDefaults(this.type)
    this.min = canUseCustomMinMax && min !== null ? min : domainDefaults.min
    this.max = canUseCustomMinMax && max !== null ? max : domainDefaults.max

    const fy = d3
      .scaleLinear()
      .range([0, this.contentHeight])
      .domain([this.model.scaleRange?.max ?? this.max, this.model.scaleRange?.min ?? this.min])
    if (!this.model.scaleRange) {
      fy.nice(10)
    }
    // @ts-expect-error - TODO scale.y types dont match
    this.model.scale.y = this.fy = fy
    this.ready = true
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    if (!this.ready) {
      return
    }
    new Line(
      {
        x1: -this.leftOffset,
        x2: -this.leftOffset + this.contentWidth,
        y1: Math.round(this.fy(0)),
        y2: Math.round(this.fy(0)),
        strokeStyle: '#ff8787',
        dashLength: 3,
      },
      this.model
    ).render(context)

    context.translate(0.5, 0.5)

    const allTickers = [...this.getTickers(), this.data.ticker]

    allTickers.forEach((ticker) => {
      context.set('strokeStyle', this.getTickerColor(ticker))
      context.beginPath()
      this.perf[ticker].values.forEach((value, index) => {
        const y = value !== null ? Math.round(this.fy(value)) : null
        if (y !== null && !isNaN(y)) {
          context.lineTo(this.model.scale.x(index), y)
        }
      })
      context.stroke()
    })

    context.translate(-0.5, -0.5)
  }

  renderCrossTextQuotePage(context: CanvasRenderingContext2D, crossIndex: number, hasBackground = true) {
    if (!context || isNaN(crossIndex) || !this.ready) {
      return
    }
    const { ChartSettings, IndicatorSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const tickers = [this.data.ticker, ...this.getTickers()]
    tickers.forEach((key, index) => {
      const tickerValue = this.perf[key]?.values[crossIndex] ?? null
      const { margin, font, labelSpacing } = IndicatorSettings.left.indicatorLabel
      const textPosX = margin.left ?? 0
      const textPosY = (font.lineHeight! + labelSpacing) * (index + 1) + margin.top!

      const text = new Text(
        {
          text: `${key} ${tickerValue !== null ? `${tickerValue.toFixed(2)}%` : NO_VALUE}`,
          x: textPosX,
          y: textPosY,
          font: Text.getMergedPropsWithDefaults('font', IndicatorSettings.left.indicatorLabel.font),
          fillStyle: this.getTickerColor(key),
          textAlign: TextAlign.left,
          textBaseline: TextBaseline.top,
        },
        this.model
      )

      // Rendering background on server charts covers labels because of small spacing between them
      if (this.model.chart().chart_layout().specificChartFunctionality !== SpecificChartFunctionality.offScreen) {
        const measuredWidth = text.measure(context)
        const labelWidth = hasBackground ? Math.max(this.indicatorLabelWidth[key] ?? 0, measuredWidth) : measuredWidth
        this.indicatorLabelWidth[key] = labelWidth

        context.set('fillStyle', hasBackground ? Colors.canvasFill : getHEXWithSpecificAplha(Colors.canvasFill, 0.8))
        context.fillRect(
          textPosX! - PADDING.XXXS,
          textPosY - PADDING.XXXS,
          labelWidth + PADDING.XXXS * 2,
          font.size! + PADDING.XXXS * 2
        )
      }

      text.render(context)
    })
  }

  renderCrossTextChartsPage(context: CanvasRenderingContext2D, crossIndex: number) {
    if (!context || isNaN(crossIndex)) {
      return
    }

    const { ChartSettings, IndicatorSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    let isVertical = true
    const labelBottom = IndicatorSettings.left.indicatorLabel.margin.top! + INDICATOR_LABEL_HEIGHT
    const tickers = [this.data.ticker, ...this.getTickers()]
    const tickerText = new Text(
      {
        font: Text.getMergedPropsWithDefaults('font', {
          ...IndicatorSettings.left.indicatorLabel.font,
          weight: 'normal',
        }),
        textAlign: TextAlign.left,
        textBaseline: TextBaseline.middle,
        background: getHEXWithSpecificAplha(Colors.canvasFill, 0.8),
        padding: { top: 0, bottom: 0, left: 5, right: 5 },
      },
      this.model
    )
    let x = IndicatorSettings.left.indicatorLabel.margin.left!
    let y =
      IndicatorSettings.left.indicatorLabel.margin.top! + (2 * INDICATOR_LABEL_HEIGHT - tickerText.attrs.lineHeight) / 2

    const maxHeight = tickerText.attrs.lineHeight * tickers.length

    const canFitItemsVertically = maxHeight + labelBottom <= this.height
    if (this.width <= MOBILE_VIEW_BREAKPOINT_WIDTH || !canFitItemsVertically) {
      isVertical = false
      x += x + this.labelWidth
    } else {
      y += IndicatorSettings.left.indicatorLabel.margin.top! + INDICATOR_LABEL_HEIGHT
    }

    tickers.forEach((key) => {
      const tickerValue = this.perf[key]?.values[crossIndex > -1 ? crossIndex : this.perf[key].lastValueIndex] ?? null
      tickerText.set({
        x,
        y,
        fillStyle: this.getTickerColor(key),
        text: `${key} ${tickerValue !== null ? `${tickerValue.toFixed(2)}%` : NO_VALUE}`,
      })
      const tickerTextWidth =
        tickerText.measure(context) + tickerText.attrs.padding.left + tickerText.attrs.padding.right
      if (
        !isVertical &&
        this.width - IndicatorSettings.left.indicatorLabel.margin.left! - IndicatorSettings.right.width! <=
          x + tickerTextWidth
      ) {
        x = 2 * IndicatorSettings.left.indicatorLabel.margin.left! + this.labelWidth
        y += tickerText.attrs.lineHeight
        tickerText.set({ x, y })
      }
      tickerText.render(context)

      if (isVertical) {
        y += tickerText.attrs.lineHeight
      } else {
        x += tickerTextWidth
      }
    })
  }

  getTickerColor(ticker: string) {
    const { ChartSettings, ColorsSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general

    if (ticker === this.data.ticker) {
      return Colors.performanceIndicatorLabel
    }

    return ColorsSettings[this.getTickers().indexOf(ticker) % ColorsSettings.length]
  }

  renderXAxis(context: CanvasRenderingContext2D) {
    if (!this.ready) {
      return
    }
    return super.renderXAxis(context)
  }

  renderYAxis(context: CanvasRenderingContext2D) {
    super.renderYAxis(context)
    const { IndicatorSettings, ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    if (!this.ready) {
      return
    }
    const translate = getTranslate({
      context,
      xOffset: IndicatorSettings.left.width,
      yOffset: IndicatorSettings.top.height,
    })
    translate.do()
    const { lineHeight, padding, size } = IndicatorSettings.right.axis.font
    const text = new Text(
      {
        x: this.contentWidth + OFFSET.M - padding!.left! + IndicatorSettings.right.axis.margin.left!,
        font: Text.getMergedPropsWithDefaults('font', { size }),
        lineHeight,
        padding: {
          left: padding!.left!,
          right: padding!.right!,
          top: padding!.top!,
          bottom: padding!.bottom!,
        },
        textBaseline: TextBaseline.middle,
        fillStyle: Colors.crossText,
      },
      this.model
    )
    const allTickers = [...this.getTickers(), this.data.ticker]

    const minY = 0
    const maxY = this.contentHeight
    allTickers.forEach((ticker) => {
      const lastValue = this.perf[ticker]?.values[this.perf[ticker]?.lastValueIndex] ?? null
      if (lastValue !== null) {
        const labelSetting = {
          text: `${lastValue.toFixed(2)}%`,
          y: Math.round(this.fy(lastValue)),
          background: this.getTickerColor(ticker),
        }
        if (labelSetting.y >= minY && labelSetting.y <= maxY) {
          text.set(labelSetting).render(context)
        }
      }
    })

    translate.undo()
  }

  renderLabelQuotePage(context: CanvasRenderingContext2D) {
    super.renderLabelQuotePage(context)
    this.renderCrossTextQuotePage(context, this.perf[this.data.ticker].lastValueIndex, false)
  }

  getTickers() {
    return this.tickers
      .split(',')
      .filter((ticker, i, arr) => ticker && ticker !== this.data.ticker && arr.indexOf(ticker) === i)
  }

  getTickersAndTimeframe() {
    const chartModel = this.model.chart()
    const chartLayoutModel = chartModel.chart_layout()
    const { timeframe, premarket, aftermarket, hasPatterns } = chartModel.quote()
    return this.getTickers().map((ticker) => ({
      ticker,
      instrument: getInstrument(ticker),
      timeframe,
      chartUuid: chartLayoutModel.uuid,
      canBeEmptyQuote: chartLayoutModel.specificChartFunctionality === SpecificChartFunctionality.offScreen,
      options: {
        premarket,
        aftermarket,
        patterns: hasPatterns,
      },
    }))
  }

  set(values: Partial<Attrs>) {
    super.set({ ...values, tickers: values.period || (values.tickers ? getSanitizedTicker(values.tickers, true) : '') })
    this.tickers = (this.tickers || '').toUpperCase()
  }

  getModalConfig() {
    const options = {
      tickers: {
        type: 'text',
        label: 'Tickers',
        name: 'tickers',
        value: !!this.tickers ? this.tickers : DEFAULT_TICKERS,
        required: true,
      },
    }

    return {
      title: PerfConfig.label,
      inputs: PerfConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        tickers: 'At least one ticker has to be provided',
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'tickers':
        return this[key].length > 0
      default:
        return false
    }
  }

  toString() {
    return this.getIsChartPageSpecificFunctionality() ? PerfConfig.shortLabel : super.toString()
  }
}

export default PricePerformance
