import Spine from '@finviz/spine'
import merge from 'lodash.merge'

import { TodoObjectHashAnyType } from '../../types/shared'
import { drawingToolsByType } from '../canvas/drawingTools'
import CanvasElement from '../canvas/element'
import BaseChart from '../charts/base_chart'
import { CanvasElementType, ChartElementType, IndicatorType } from '../constants/common'
import IndicatorElement from '../indicators/indicator'
import { financialIndicators } from '../indicators/indicators'
import { getIsSSr, getUuid } from '../utils/helpers'
import Pane from './pane'
import { changeElementZIndexes } from './utils'

class Element extends Spine.Model {
  static initClass(paneModel: typeof Pane) {
    this.configure('Element', 'instance', 'lastChange', 'zIndex', 'elementId')
    this.belongsTo('pane', paneModel)
  }

  declare instance: CanvasElement
  declare pane_id: string
  declare destroyed: true | undefined
  declare pane: () => Pane
  lastChange: number | null = null
  elementId: string | null = null
  zIndex = -1

  generateUuid = getIsSSr() ? undefined : getUuid

  constructor(...args: any[]) {
    super(...args)
    const elementAttrs = args[0]
    this.elementId = elementAttrs?.elementId ?? this.generateUuid?.()
    this.one('create', () => {
      this.updateAttributes({
        zIndex: elementAttrs?.zIndex ?? this.pane().getElementZIndexRange().max + 1,
      })
    })
  }

  replace(instance: CanvasElement | IndicatorElement) {
    const oldInstance = this.instance
    this.updateAttribute('instance', instance)
    this.trigger('replace', this, oldInstance)
  }

  makeClone() {
    const { instance, pane_id } = this
    const { attrs } = instance
    const { scale } = instance.model
    const clonedObj = merge({ pane_id }, attrs)
    const cloned = drawingToolsByType[instance.type as CanvasElementType].fromObject(clonedObj, instance.model)
    const { ThumbSettings } = this.pane().getChartLayoutSettings()
    const offset = ThumbSettings.size + ThumbSettings.borderWidth * 2
    cloned.moveBy(scale.x.invert(offset), scale.y.invert(offset) - scale.y.invert(0))
    cloned.cachePointPositionTimestamp()
    Element.create({ instance: cloned, pane_id })
    this.trigger('makeClone', this, cloned)
  }

  moveToZIndex(zIndex: number) {
    const currentZIndex = this.zIndex
    const directionCoef = zIndex > currentZIndex ? 1 : -1

    if (this.isChart() || this.isIndicator()) {
      if (this.zIndex !== 0) {
        this.updateAttributes({ zIndex: 0 })
      }
      this.pane()
        .getAllElements()
        .filter((element) => {
          const shouldMove = directionCoef === 1 ? element.zIndex <= zIndex : element.zIndex >= zIndex
          return shouldMove && element.isDrawing()
        })
        .forEach((element) => element.moveToZIndex(directionCoef * -1))

      return
    }

    const newZIndex = zIndex === 0 ? directionCoef : zIndex // 0 is protected value for chart/indicator element
    const { min, max } = this.pane().getElementZIndexRange()
    if (
      currentZIndex === newZIndex ||
      (currentZIndex === min && directionCoef === -1) ||
      (currentZIndex === max && directionCoef === 1)
    ) {
      return
    }
    const { elementsBelowZero, elementsAboveZero } = this.pane().getBelowAboveZeroElements()

    changeElementZIndexes({
      elementsArray: elementsBelowZero,
      addElement: newZIndex < 0 ? this : undefined,
      oldZIndex: currentZIndex,
      newZIndex,
      isBelowZero: true,
    })

    changeElementZIndexes({
      elementsArray: elementsAboveZero,
      addElement: newZIndex > 0 ? this : undefined,
      oldZIndex: currentZIndex,
      newZIndex,
    })
  }

  bringToFront() {
    this.moveToZIndex(this.pane().getElementZIndexRange().max + 1)
  }

  sendToBack() {
    this.moveToZIndex(this.pane().getElementZIndexRange().min - 1)
  }

  bringForward() {
    this.moveToZIndex(this.zIndex + 1)
  }

  sendBackward() {
    this.moveToZIndex(this.zIndex - 1)
  }

  isChart(): this is { instance: InstanceType<typeof BaseChart> } & BaseChart {
    return !!this.instance?.type.startsWith('charts/')
  }

  getIsChartType(chartType: ChartElementType) {
    return this.instance?.type === chartType
  }

  isFinancialIndicator(): this is { instance: InstanceType<typeof IndicatorElement> } & IndicatorElement {
    return this.isIndicator() && financialIndicators.includes(this.instance!.type as IndicatorType)
  }

  isIndicator(): this is { instance: InstanceType<typeof IndicatorElement> } & IndicatorElement {
    return !!this.instance?.type.startsWith('indicators/')
  }

  isOverlay() {
    return !!this.instance?.type.startsWith('overlays/')
  }

  isDrawing() {
    return !!this.instance?.type.startsWith('canvas/')
  }

  isChartEvent() {
    return !!this.instance?.type.startsWith('chartEvent/')
  }

  isMouseDown() {
    return !!this.instance?.isMouseDown
  }

  hasOngoingInteraction() {
    return this.isMouseDown() || this.instance.isEditInProgress
  }

  toObject() {
    return {
      ...this.instance.toObject(),
      elementId: this.elementId,
      zIndex: this.zIndex,
    }
  }

  toConfig() {
    return {
      ...this.instance.toConfig(),
      elementId: this.elementId,
      zIndex: this.zIndex,
    }
  }

  destroyCascade(options?: TodoObjectHashAnyType) {
    return this.destroy(options)
  }

  refreshElementId() {
    this.updateAttribute('elementId', this.generateUuid?.())
  }
}

export default Element
