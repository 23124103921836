import Spine, { Collection } from '@finviz/spine'
import omit from 'lodash.omit'

import {
  ChartConfigChartPane,
  CustomSpineEvents,
  Instrument,
  ObjectHash,
  TodoObjectHashAnyType,
} from '../../types/shared'
import PerfChart from '../charts/perf_chart'
import {
  DateRangeType,
  IndicatorType,
  QuotePollingIntervalInMs,
  ScaleType,
  SpecificChartFunctionality,
  TIMEFRAME,
} from '../constants/common'
import { getCanvasElementByType } from '../helpers/get-canvas-element-by-type'
import PricePerformance from '../indicators/perf'
import Utils from '../utils'
import { IDividends, IEarnings, ISplit } from '../utils/chart-events-utils'
import ChartEventElement from './chart-event-element'
import { ChartPartToAttrsSyncMap, ChartSyncablePart } from './chart/contstants'
import ChartLayout from './chart_layout'
import Pane from './pane'
import Quote from './quote'
import { getChartLayoutSettings } from './settings'

class Chart extends Spine.Model {
  static initClass(paneModel: typeof Pane, quoteModel: typeof Quote, chartLayoutModel: typeof ChartLayout) {
    this.configure(
      'Chart',
      'width',
      'height',
      'timeframe',
      'dateRange',
      'scale',
      'leftOffset',
      'ticker',
      'instrument',
      'fx',
      'fy',
      'zoomFactor',
      'refreshData',
      'stretch',
      'isHideDrawingsActive',
      'isScrolled',
      'firstBarClose',
      'premarket',
      'aftermarket',
      'hasChartEvents'
    )
    this.hasMany('panes', paneModel)
    this.belongsTo('quote', quoteModel)
    this.belongsTo('chart_layout', chartLayoutModel)
  }

  declare stretch: boolean
  declare chart_layout_id: string
  declare chart_layout: () => ChartLayout
  declare quote_id: string
  declare quote: () => Quote
  declare panes: () => Collection<Pane>
  declare refreshData: boolean | number
  declare dateRange: DateRangeType
  declare width: number
  declare height: number
  declare timeframe: TIMEFRAME
  declare leftOffset: number
  declare ticker: string
  declare instrument: Instrument
  declare fx: (x: number) => number
  declare fy: (x: number) => number
  declare zoomFactor: number
  declare isHideDrawingsActive: boolean
  declare isScrolled: boolean
  declare scale: ScaleType
  declare firstBarClose: number
  declare premarket: boolean
  declare aftermarket: boolean
  declare hasChartEvents: boolean

  getChartPane() {
    return this.panes()
      .all()
      .find((pane: Pane) =>
        pane
          .elements()
          .all()
          .some((el) => el.isChart())
      )
  }

  getChartElement() {
    for (const pane of this.panes().all()) {
      for (const el of pane.elements().all()) {
        if (el.isChart()) {
          return el
        }
      }
    }
  }

  getChartType() {
    return this.getChartElement()?.instance.type
  }

  getRefreshInterval() {
    let defaultRefreshInterval: number | null = null
    if (typeof this.refreshData === 'number') {
      defaultRefreshInterval = this.refreshData
    } else if (this.refreshData === true) {
      defaultRefreshInterval = QuotePollingIntervalInMs.Default
    }

    const isStock = this.instrument === Instrument.Stock
    const isPremium = window.FinvizSettings.hasUserPremium
    let customRefreshInterval = QuotePollingIntervalInMs.Elite
    if (!isPremium) {
      customRefreshInterval = QuotePollingIntervalInMs.Free
    } else if (isStock && !Utils.isStockFastRefreshAvailable()) {
      customRefreshInterval = QuotePollingIntervalInMs.EliteStocksReduced
    }

    return defaultRefreshInterval && Math.max(defaultRefreshInterval, customRefreshInterval)
  }

  toObject() {
    const panes = this.panes()
      .all()
      .map((pane) => pane.toObject())
    return {
      width: this.width,
      dateRange: this.dateRange,
      height: this.height,
      timeframe: this.timeframe,
      scale: this.scale,
      leftOffset: this.leftOffset,
      ticker: this.ticker,
      instrument: this.instrument,
      zoomFactor: this.zoomFactor,
      refreshData: this.refreshData,
      stretch: this.stretch,
      chartId: this.cid,
      panes,
      isHideDrawingsActive: this.isHideDrawingsActive,
      isScrolled: this.isScrolled,
      premarket: this.premarket,
      aftermarket: this.aftermarket,
      hasChartEvents: this.hasChartEvents,
    }
  }

  toConfig(omitKeys = [] as string[]) {
    const panes = this.panes()
      .all()
      .map((pane) => pane.toConfig(omitKeys))
    return omit(
      {
        width: this.width,
        height: this.height,
        timeframe: this.timeframe,
        scale: this.scale,
        leftOffset: this.leftOffset,
        ticker: this.ticker,
        instrument: this.instrument,
        zoomFactor: this.zoomFactor,
        refreshData: this.refreshData,
        stretch: this.stretch,
        chartId: this.cid,
        panes,
        isHideDrawingsActive: this.isHideDrawingsActive,
        isScrolled: this.isScrolled,
        premarket: this.premarket,
        aftermarket: this.aftermarket,
        hasChartEvents: this.hasChartEvents,
      },
      omitKeys
    )
  }

  destroyCascade(options?: TodoObjectHashAnyType) {
    this.panes()
      .all()
      .forEach((pane) => {
        pane.destroyCascade()
      })
    return this.destroy(options)
  }

  getChartLayoutSettings() {
    return getChartLayoutSettings(this.chart_layout())
  }

  getIsDisabled() {
    return this.quote()?.close.length === 0
  }

  getIsScrollable() {
    return this.chart_layout().scrollable
  }

  getAllPanes() {
    return this.panes().all()
  }

  getAllValidPanes() {
    const cotKeys = Object.keys(this.quote().COTs ?? {})
    return this.getAllPanes().filter((pane) => {
      const mainElement = pane.mainElement()
      if (mainElement?.isIndicator() && mainElement.instance.type === IndicatorType.Cot) {
        return cotKeys.includes(mainElement.instance.attrs.code)
      }
      return true
    })
  }

  getAllElements() {
    return this.getAllPanes().flatMap((pane) => pane.getAllElements())
  }

  getAllQuotes(): Quote[] {
    const perfQuotes = this.getAllElements()
      .filter(({ instance }) => instance.type === IndicatorType.Perf)
      .flatMap(({ instance }) => Object.values((instance as unknown as PricePerformance).quotes))

    let quotePerfTickers: Quote[] = []
    if (this.chart_layout().specificChartFunctionality === SpecificChartFunctionality.quotePerf) {
      const perfChart = this.getChartElement()?.instance as PerfChart | undefined
      if (perfChart) {
        quotePerfTickers = Quote.select(
          (q: Quote) => perfChart.attrs.tickers.includes(q.ticker) && [TIMEFRAME.d, TIMEFRAME.m].includes(q.timeframe)
        )
      }
    }

    return [...perfQuotes, ...quotePerfTickers, this.quote()].filter(
      (quote, index, quotes) => quote && quotes.findIndex((q) => q?.id === quote.id) === index
    )
  }

  createPaneCascade(paneProperties: ChartConfigChartPane) {
    const paneModel = this.panes().create<Pane>(paneProperties)

    paneProperties.elements?.forEach(({ zIndex, elementId, ...element }) => {
      const instance = getCanvasElementByType(element.type).fromObject(element, paneModel)
      paneModel.elements().create({ instance, zIndex, elementId })
      paneModel.chart().trigger(CustomSpineEvents.IndicatorsChange)
    })

    const chartOrIndicator = paneModel.getChartOrIndicatorElement()
    if (paneModel.mainElement()?.elementId !== chartOrIndicator?.elementId) {
      paneModel.updateAttributes({ mainElement: chartOrIndicator })
    }

    return paneModel
  }

  updateAttributesAndSync<T extends ObjectHash = ObjectHash>(value: T) {
    const attrsInSync = Object.entries(ChartPartToAttrsSyncMap)
      .filter(([key]) => this.getIsChartPartInSync(key as unknown as ChartSyncablePart))
      .flatMap(([_, modelAttr]) => modelAttr)
    this.updateAttributes(value)

    if (attrsInSync.length > 0) {
      this.chart_layout()
        .getAllCharts()
        .forEach((chartModel) => {
          if (this.eql(chartModel)) {
            return
          }
          const newAttrs: ObjectHash = {}
          attrsInSync.forEach((modelAttr) => {
            if (value.hasOwnProperty(modelAttr)) {
              newAttrs[modelAttr] = value[modelAttr]
            }
          })
          chartModel.updateAttributes(newAttrs)
        })
    }
  }

  setSyncChartParts(chartParts: ChartSyncablePart | ChartSyncablePart[], isInSync: boolean) {
    this.chart_layout().setSyncChartParts(chartParts, isInSync)
  }

  getIsChartPartInSync(chartPart: ChartSyncablePart) {
    return this.chart_layout().getIsChartPartInSync(chartPart)
  }

  getHasPatterns() {
    return this.getAllElements().some((element) => {
      if (element.isChart()) {
        return element.instance.hasOverlay('patterns')
      }

      return false
    })
  }

  getQuoteFinancialAttachments() {
    return this.getAllElements()
      .filter((el) => el.isFinancialIndicator())
      .flatMap((indicatorElement) => indicatorElement.instance.config.quoteFinancialAttachments)
      .filter((quoteFinancialAttachment, index, arr) => arr.indexOf(quoteFinancialAttachment) === index)
  }

  getQuoteRawTicker(): string | null {
    return this.quote()?.getRawTicker() ?? null
  }

  setChartEvents(chartEvents?: Array<IEarnings | IDividends | ISplit>, shouldClear = false) {
    const chartPane = this.getChartPane()
    if (!chartPane) {
      return
    }
    const allChartEvents = chartPane.chartEvents().all()
    if (shouldClear) {
      allChartEvents.forEach((chartEvent) => chartEvent.destroyCascade())
    }

    const events = shouldClear
      ? chartEvents
      : chartEvents?.filter((chartEvent) => !allChartEvents.some(({ elementId }) => chartEvent.elementId === elementId))

    events?.forEach(({ elementId, eventType, dateTimestamp }) => {
      const newChartEvent = chartPane.chartEvents().create<ChartEventElement>({
        instance: getCanvasElementByType(eventType).fromObject({ positionTimestamps: { x: dateTimestamp } }, chartPane),
        elementId,
      })
      newChartEvent.instance.updateScales()
    })

    chartPane.updateChartEventsZIndexes()
  }
}

export default Chart
