import { Attrs, RMIConfig } from '../indicators/configs/rmi'
import { MainCalculation } from './main'

type DefaultCalculatedValuesType = {
  rmi: number[]
}

export class RMICalculation extends MainCalculation<Attrs, DefaultCalculatedValuesType> {
  static config = RMIConfig

  calculate() {
    const { period, momentum } = this.options
    const { close } = this.quote
    this._calculatedValues = this.getDefaultCalculatedValues()

    if (close.length < momentum + period) return

    let sumUp = 0
    let sumDown = 0
    let averageDown: number, averageUp: number

    for (let i = momentum; i < close.length; i++) {
      let upChange = 0
      let downChange = 0
      if (close[i] > close[i - momentum]) {
        upChange = close[i] - close[i - momentum]
      }
      if (close[i] < close[i - momentum]) {
        downChange = close[i - momentum] - close[i]
      }

      if (i - momentum < period) {
        sumUp += upChange
        sumDown += downChange
        averageUp = sumUp / (i - momentum + 1)
        averageDown = sumDown / (i - momentum + 1)
      } else {
        averageUp = (averageUp! * (period - 1) + upChange) / period
        averageDown = (averageDown! * (period - 1) + downChange) / period
      }

      if (i < period + momentum - 1) continue
      this._calculatedValues.rmi[i] =
        averageDown === 0 ? 100 : averageUp === 0 ? 0 : 100 - 100 / (1 + averageUp / averageDown)
    }
  }
}
