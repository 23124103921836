import { Attrs, RSIConfig } from '../indicators/configs/rsi'
import { MainCalculation } from './main'
import { RMICalculation } from './rmi'

type DefaultCalculatedValuesType = {
  rsi: number[]
}

export class RsiCalculation extends MainCalculation<Attrs, DefaultCalculatedValuesType> {
  static config = RSIConfig

  calculate() {
    const { period } = this.options

    this._calculatedValues = this.getDefaultCalculatedValues()

    const rmiCalculation = new RMICalculation({
      quote: this.quote,
      options: { period: period, momentum: 1 },
    })

    rmiCalculation.calculate()
    this._calculatedValues.rsi = rmiCalculation.calculatedValues.rmi
  }
}
