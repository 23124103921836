import { IndicatorType } from '../../constants/common'
import IndicatorBaseConfig from './indicatorBaseConfig'

export type Options = {
  period: number
}

export type Attrs = Options

export class WRConfig extends IndicatorBaseConfig {
  static type = IndicatorType.Wr
  static abbreviation = 'wr'
  static label = 'Williams %R'
  static shortLabel = 'W%R'
  static optionsOrder = ['period'] as const
  static optionsLabels = { period: 'Period' }
  static inputsOrder = this.optionsOrder
  static inputsLabels = this.optionsLabels
  static calculatedValuesOrder = ['r'] as const
  static calculatedValuesLabels = { r: '%R' }
  static defaultCalculatedValueOption = this.calculatedValuesOrder[0]
}
