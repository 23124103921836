import * as d3 from 'd3'

import { Theme } from '../../types/shared'
import Text from '../canvas/text'
import tailwindColors from '../constants/colors'
import { INDICATOR_LABEL_HEIGHT, TextAlign, TextBaseline } from '../constants/common'
import utils from '../utils'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { Attrs, COTConfig } from './configs/cot'
import Indicator from './indicator'

export interface ICOT {
  code: string
  name: string
  units: string
  commercialHedgers: number[]
  largeTraders: number[]
  smallTraders: number[]
}

type ColorsKeys = keyof Pick<ICOT, 'commercialHedgers' | 'largeTraders' | 'smallTraders'>

class COT extends Indicator<Attrs> {
  static config = COTConfig

  static getNumOfBarsBuffer() {
    return 0
  }

  compute() {
    if (!this.isComputeNecessary() || !this.data.COTs) return
    const cotQuoteCode = Object.keys(this.data.COTs)[0]
    if (!this.attrs.code && cotQuoteCode) {
      this.attrs.code = cotQuoteCode
    }
    const cot = this.data.COTs[this.attrs.code]
    const [min, max] = utils.minMax(cot.commercialHedgers, cot.largeTraders, cot.smallTraders)
    this.max = Math.max(Math.abs(max), Math.abs(min))
    this.min = -this.max
  }

  getLineColors() {
    const isDarkMode = this.model.chart().chart_layout().theme === Theme.dark
    return {
      commercialHedgers: tailwindColors.green[400],
      largeTraders: tailwindColors.red[400],
      smallTraders: tailwindColors.blue[isDarkMode ? 300 : 500],
    }
  }

  getValueLabelsAtIndex(index: number) {
    if (!this.data.COTs) return []
    const colors = this.getLineColors()

    return Object.keys(colors).map((key) => {
      const data = this.data.COTs[this.attrs.code][key as ColorsKeys]
      return {
        color: colors[key as ColorsKeys],
        text: this.getValueLabel(data[index]),
      }
    })
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    if (!this.data.COTs) return
    context.translate(0.5, 0.5)
    const colors = this.getLineColors()
    for (const key in colors) {
      context.set('strokeStyle', colors[key as ColorsKeys])
      context.beginPath()
      const data = this.data.COTs[this.attrs.code][key as ColorsKeys]
      drawInVisibleArea({
        quote: this.data,
        paneModel: this.model,
        leftOffset: this.leftOffset,
        width: this.width,
        drawBarCallback: (i: number, x: number) => {
          context.lineTo(x, Math.round(this.fy(data[i])))
        },
      })
      context.stroke()
    }
    context.translate(-0.5, -0.5)
  }

  renderLabel(context: CanvasRenderingContext2D) {
    if (!this.data.COTs) return
    const { ChartSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const name = new Text(
      {
        text: this.toString(),
        x: 8,
        y: 16,
        font: { size: 10, weight: 'bold' },
        fillStyle: Colors.text,
        textAlign: TextAlign.left,
        textBaseline: TextBaseline.bottom,
      },
      this.model
    )
    name.render(context)
    const secondarylabelX = 38 + name.measure()
    const secondarylabel = new Text(
      {
        text: this.data.COTs[this.attrs.code].units as string,
        x: secondarylabelX,
        y: 16,
        font: { size: 8 },
        fillStyle: Colors.text,
        textAlign: TextAlign.left,
        textBaseline: TextBaseline.bottom,
      },
      this.model
    )

    this.labelWidth = secondarylabelX + 8 + secondarylabel.measure(context)
    secondarylabel.render(context)

    this.renderAdditionalLabels(context)
  }

  renderLabelChartsPage(context: CanvasRenderingContext2D) {
    const { x, y, labelWidth } = super.renderLabelChartsPage(context) as { x: number; y: number; labelWidth: number }

    const { IndicatorSettings } = this.getChartLayoutSettings()
    const isDarkMode = this.model.chart().chart_layout().theme === Theme.dark
    const secondarylabelX = labelWidth + x * 2
    const fontSettings = IndicatorSettings.left.indicatorLabel.font
    const scaleCoefficient = 0.7

    const secondarylabel = new Text(
      {
        text: this.data.COTs[this.attrs.code].units as string,
        x: secondarylabelX + x,
        font: Text.getMergedPropsWithDefaults('font', {
          ...fontSettings,
          size: fontSettings.size ? fontSettings.size * scaleCoefficient : undefined,
          weight: 'normal',
        }),
        fillStyle: tailwindColors.gray[isDarkMode ? 50 : 900],
        textAlign: TextAlign.left,
        textBaseline: TextBaseline.middle,
      },
      this.model
    )
    secondarylabel.set({
      y: y + (INDICATOR_LABEL_HEIGHT * scaleCoefficient - (secondarylabel.attrs.lineHeight * scaleCoefficient) / 2),
    })
    this.labelWidth = secondarylabelX + x + secondarylabel.measure(context)
    secondarylabel.render(context)

    this.renderAdditionalLabels(context)
  }

  renderAdditionalLabels(context: CanvasRenderingContext2D) {
    const { ChartSettings, IndicatorSettings } = this.getChartLayoutSettings()
    const { Colors } = ChartSettings.general
    const colors = this.getLineColors()

    new Text(
      {
        text: 'COT',
        x: 23,
        y: IndicatorSettings.top.height + (IndicatorSettings.bottom.height + this.contentHeight) / 2,
        angle: -90,
        font: { size: 10, weight: '900', family: 'Lato, sans-serif' },
        fillStyle: Colors.textSecondary,
        textAlign: TextAlign.center,
        textBaseline: TextBaseline.alphabetic,
      },
      this.model
    ).render(context)

    const text = new Text(
      {
        text: 'Commercial Hedgers',
        x: IndicatorSettings.left.width,
        y: IndicatorSettings.top.height + this.contentHeight + IndicatorSettings.bottom.height - 1, // allign to very bottom of indicator with 1 pixel bottom padding
        font: { size: 8, weight: 'bold' },
        fillStyle: colors.commercialHedgers,
        textAlign: TextAlign.left,
        textBaseline: TextBaseline.bottom,
      },
      this.model
    )
    text.render(context)

    let x = text.get('x')! + 8 + text.measure()
    text.set({ text: 'Large Traders', x, fillStyle: colors.largeTraders })
    text.render(context)

    x += 8 + text.measure()
    text.set({ text: 'Small Traders', x, fillStyle: colors.smallTraders })
    text.render(context)
  }

  formatAxis(value: number) {
    // https://github.com/d3/d3-format/blob/master/README.md#locale_formatPrefix
    const scaleY = this.model.scale.y as unknown as d3.ScaleLinear<number, number>
    return scaleY.tickFormat(4, 's')(value).replace(/G/, 'B')
  }

  getModalConfig() {
    return {
      title: COTConfig.label,
      inputs: [],
      inputsErrorMessages: {},
    }
  }

  toString() {
    return this.data?.COTs ? (this.data.COTs[this.attrs.code].name as string) : ''
  }

  toConfig<T extends Indicator>(): T['attrs'] & { type: T['type'] } {
    return {
      type: this.type,
      code: this.attrs.code,
    }
  }
}

export default COT
