import { WRCalculation } from '../indicator-calculation/wr'
import { Attrs, WRConfig } from './configs/wr'
import Indicator from './indicator'

class WilliamsR extends Indicator<Attrs> {
  static config = WRConfig

  declare period: number
  declare wrCalculation: WRCalculation

  set(values: Partial<Attrs>) {
    super.set(values)
    this.parsePeriodInt(values)
  }

  compute() {
    if (this.isComputeNecessary()) {
      this.wrCalculation = new WRCalculation({
        quote: this.data,
        options: { period: this.period },
      })
      this.wrCalculation.calculate()

      this.lastValue = this.wrCalculation.calculatedValues.r.last() ?? null
    }
    const { min, max } =
      this.wrCalculation.calculatedValues.r.length > 0
        ? this.computeVisibleMinMax(this.wrCalculation.calculatedValues.r)
        : this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getValueLabelsAtIndex(index: number) {
    return this.getOversoldOverboughtValueLabelsAtIndex(index, this.wrCalculation.calculatedValues.r)
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    this.renderOversoldOverbought(context, this.wrCalculation.calculatedValues.r, this.period - 1, -80, -50, -20)
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 14,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: WRConfig.label,
      inputs: WRConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default WilliamsR
